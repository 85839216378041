import { Container } from "@mui/material";
import logo from "../images/logo.png";

const CookiesPolicy = () => {
  return (
    <Container>
          <img
        src={logo}
        alt="white-logo"
        style={{
          width: 200,
          height: 200,
          margin: "auto",
        }}
      />
      <div>
        <div className="relative px-4 sm:px-6 lg:px-8">
          <div className="text-lg max-w-prose mx-auto">
            <h1>
              <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl lg:text-5xl">
                Cookies Policy
              </span>
              <br />
              <br />
              <span className="block text-sm pt-4 text-center text-gray-500 font-semibold tracking-wide uppercase">
                Last updated: February 26, 2021
              </span>
            </h1>
          </div>
          <div className="mt-12 prose sm:max-w-screen-sm md:max-w-screen-md lg:max-w-screen-lg prose-indigo prose-lg text-gray-600 mx-auto">
            <div>
              <p>
                This Cookies Policy explains what Cookies are and how We use
                them. You should read this policy so You can understand what
                type of cookies We use, or the information We collect using
                Cookies and how that information is used.
              </p>
              <p>
                Cookies do not typically contain any information that personally
                identifies a user, but personal information that we store about
                You may be linked to the information stored in and obtained from
                Cookies. For further information on how We use, store and keep
                your personal data secure, see our Privacy Policy.
              </p>
              <p>
                We do not store sensitive personal information, such as mailing
                addresses, account passwords, etc. in the Cookies We use.
              </p>
              <h2>Interpretation and Definitions</h2>
              <h3>Interpretation</h3>
              <p>
                The words of which the initial letter is capitalized have
                meanings defined under the following conditions. The following
                definitions shall have the same meaning regardless of whether
                they appear in singular or in plural.
              </p>
              <h3>Definitions</h3>
              <p>For the purposes of this Cookies Policy:</p>
              <ol>
                <li>
                  Company (referred to as either “the Company”, “We”, “Us” or
                  “Our” in this Cookies Policy) refers to YAASS, LLC, 55 E.
                  Monroe Street, Suite 3800 Chicago, Illinois, 60603.
                </li>
                <li>
                  Cookies means small files that are placed on Your computer,
                  mobile device or any other device by a website, containing
                  details of your browsing history on that website among its
                  many uses.
                </li>
                <li>
                  Website refers to YAASS, accessible from
                  <a href="https://www.termsfeed.com/live/www.yaass.com">
                    yaass.com
                  </a>
                </li>
                <li>
                  You means the individual accessing or using the Website, or a
                  company, or any legal entity on behalf of which such
                  individual is accessing or using the Website, as applicable.
                </li>
              </ol>
              <h2>The use of the Cookies</h2>
              <h3>Type of Cookies We Use</h3>
              <p>
                Cookies can be “Persistent” or “Session” Cookies. Persistent
                Cookies remain on your personal computer or mobile device when
                You go offline, while Session Cookies are deleted as soon as You
                close your web browser.
              </p>
              <p>
                We use both session and persistent Cookies for the purposes set
                out below:
              </p>
              <ol>
                <li>
                  Necessary / Essential Cookies
                  <br />
                  Type: Session Cookies
                  <br />
                  Administered by: Us
                  <br />
                  Purpose: These Cookies are essential to provide You with
                  services available through the Website and to enable You to
                  use some of its features. They help to authenticate users and
                  prevent fraudulent use of user accounts. Without these
                  Cookies, the services that You have asked for cannot be
                  provided, and We only use these Cookies to provide You with
                  those services.
                </li>
                <li>
                  Cookies Policy / Notice Acceptance Cookies
                  <br />
                  Type: Persistent Cookies
                  <br />
                  Administered by: Us
                  <br />
                  Purpose: These Cookies identify if users have accepted the use
                  of cookies on the Website.
                </li>
                <li>
                  Functionality Cookies
                  <br />
                  Type: Persistent Cookies
                  <br />
                  Administered by: Us
                  <br />
                  Purpose: These Cookies allow us to remember choices You make
                  when You use the Website, such as remembering your login
                  details or language preference. The purpose of these Cookies
                  is to provide You with a more personal experience and to avoid
                  You having to re-enter your preferences every time You use the
                  Website.
                </li>
                <li>
                  Tracking and Performance Cookies
                  <br />
                  Type: Persistent Cookies
                  <br />
                  Administered by: Third-Parties
                  <br />
                  Purpose: These Cookies are used to track information about
                  traffic to the Website and how users use the Website. The
                  information gathered via these Cookies may directly or
                  indirectly identify you as an individual visitor. This is
                  because the information collected is typically linked to a
                  pseudonymous identifier associated with the device you use to
                  access the Website. We may also use these Cookies to test new
                  pages, features or new functionality of the Website to see how
                  our users react to them.
                </li>
                <li>
                  Targeting and Advertising Cookies
                  <br />
                  Type: Persistent Cookies
                  <br />
                  Administered by: Third-Parties
                  <br />
                  Purpose: These Cookies track your browsing habits to enable Us
                  to show advertising which is more likely to be of interest to
                  You. These Cookies use information about your browsing history
                  to group You with other users who have similar interests.
                  Based on that information, and with Our permission, third
                  party advertisers can place Cookies to enable them to show
                  adverts which We think will be relevant to your interests
                  while You are on third party websites.
                </li>
                <li>
                  Social Media Cookies
                  <br />
                  Type: Persistent Cookies
                  <br />
                  Administered by: Third-Parties
                  <br />
                  Purpose: In addition to Our own Cookies, We may also use
                  various third party plug-ins from social media networking
                  websites such as Facebook, Instagram, Twitter or Google+ to
                  report usage statistics of the Website and to provide social
                  media features. These third party plug-ins may store Cookies.
                  We do not control these Social Media Cookies. Please refer to
                  the relevant social media networking’s website privacy
                  policies for information about their cookies.
                </li>
              </ol>
              <h3>Your Choices Regarding Cookies</h3>
              <p>
                If You prefer to avoid the use of Cookies on the Website, first
                You must disable the use of Cookies in your browser and then
                delete the Cookies saved in your browser associated with this
                website. You may use this option for preventing the use of
                Cookies at any time.
              </p>
              <p>
                If You do not accept Our Cookies, You may experience some
                inconvenience in your use of the Website and some features may
                not function properly.
              </p>
              <p>
                If You’d like to delete Cookies or instruct your web browser to
                delete or refuse Cookies, please visit the help pages of your
                web browser.
              </p>
              <ol>
                <li>
                  For the Chrome web browser, please visit this page from
                  Google:
                  <a
                    href="https://support.google.com/accounts/answer/32050"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://support.google.com/accounts/answer/32050
                  </a>
                </li>
                <li>
                  For the Internet Explorer web browser, please visit this page
                  from Microsoft:
                  <a href="http://support.microsoft.com/kb/278835">
                    http://support.microsoft.com/kb/278835
                  </a>
                </li>
                <li>
                  For the Firefox web browser, please visit this page from
                  Mozilla:
                  <a
                    href="https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored
                  </a>
                </li>
                <li>
                  For the Safari web browser, please visit this page from Apple:
                  <a
                    href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac
                  </a>
                </li>
              </ol>
              <p>
                For any other web browser, please visit your web browser’s
                official web pages.
              </p>
              <h3>More Information about Cookies</h3>
              <p>
                You can learn more about cookies here:
                <a
                  href="https://www.termsfeed.com/blog/cookies/"
                  target="_blank"
                  rel="noreferrer"
                >
                  All About Cookies by TermsFeed
                </a>
                .
              </p>
              <h3>Contact Us</h3>
              <p>
                If you have any questions about this Cookies Policy, You can
                contact us:
              </p>
              <p>By email: info@yaass.com</p>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default CookiesPolicy;

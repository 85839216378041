import { ThemeProvider } from "@mui/material";
import { Route, Routes } from "react-router-dom";
import Campaign from "./pages/campaign";
import CommunityGuidelines from "./pages/communityGuidelines";
import CookiesPolicy from "./pages/cookiesPolicy";
import Landing from "./pages/landing";
import PageNotFound from "./pages/pageNotFound";
import PrivacyPolicy from "./pages/privacyPolicy";
import TermsAndConditions from "./pages/termsAndConditions";
import Thankyou from "./pages/thankyou";
import DeleteYourAccount from "./pages/deleteYourAccount";
import theme from "./style/theme";

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/campaign" element={<Campaign />} />
          <Route path="/thankyou" element={<Thankyou />} />
          <Route path="/deleteYourAccount" element={<DeleteYourAccount/>} />
          <Route
            path="/pages/privacy-policy.html"
            element={<PrivacyPolicy />}
          />
          <Route
            path="/pages/community-guidelines.html"
            element={<CommunityGuidelines />}
          />
          <Route
            path="/pages/terms-and-conditions.html"
            element={<TermsAndConditions />}
          />
          <Route
            path="/pages/cookies-policy.html"
            element={<CookiesPolicy />}
          />
          <Route
            path="*"
            element={<PageNotFound />}
          />
          
        </Routes>
      </ThemeProvider>
    </div>
  );
}

export default App;

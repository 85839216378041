import { Button, TextField, Typography } from "@mui/material";
import { Container } from "@mui/system";
import { FunctionComponent, useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../images/logo.png";
import poloroids from "../images/polaroids.png";
import poloroidsMobile from "../images/polaroids-mobile.png";

const Campaign: FunctionComponent = () => {
  const isMobile = window.innerWidth <= 800;
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [err, setErr] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    const date = new Date();
    console.log(date);
    e.preventDefault();
    await fetch("https://api.yaass.com/v1/campaign", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name: name,
        email: email,
        phoneNumber: phone,
        campaignDate: "Mon Jul 08 2022 22:05:35 GMT+0530 (India Standard Time)",
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status) {
          navigate("/thankyou");
        } else {
          setErr(data.message);
        }
      });
    console.log(email, name, phone);
  };

  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: isMobile? "100vw" :"450px",
      }}
    >
    
      <img
        src={logo}
        alt="white-logo"
        style={{
          width: 200,
          height: 180,
        }}
      />
      <Typography variant={"h1"}>BUS PARTY WITH YAASS!</Typography>
      {err && (
        <Typography
          variant={"body2"}
          sx={{
            color: "red",
          }}
        >
          {err}
        </Typography>
      )}
      <Typography
        variant={"h3"}
        sx={{
          m: 3,
          lineHeight: "17px",
          textAlign: "center",
          letterSpacing: "4px",
        }}
      >
        JULY 15TH 9PM - MIDNIGHT 123 CHICAGO AVE, CHICAGO, ILLINOIS
      </Typography>
      <Typography
        variant={"body2"}
        sx={{
          textAlign: "center",
          letterSpacing: "1px",
        }}
      >
        Join us for a night of fun! Ride around town in a party bus sponsored by
        YAASS!
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          fullWidth
          label={"Name"}
          margin={"dense"}
          required
          onChange={(e) => setName(e.target.value)}
          sx={{
            backgroundColor: "#FDFDFD",
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#D3D3D3",
              },
              "&:hover fieldset": {
                borderColor: "#D3D3D3",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#D3D3D3",
              },
            },
          }}
        />
        <TextField
          fullWidth
          label={"Phone"}
          margin={"dense"}
          required
          onChange={(e) => setPhone(e.target.value)}
          sx={{
            backgroundColor: "#FDFDFD",
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#D3D3D3",
              },
              "&:hover fieldset": {
                borderColor: "#D3D3D3",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#D3D3D3",
              },
            },
          }}
        />
        <TextField
          fullWidth
          label={"Email"}
          margin={"dense"}
          required
          onChange={(e) => setEmail(e.target.value)}
          sx={{
            backgroundColor: "#FDFDFD",
            borderColor: "#FDFDA",
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#D3D3D3",
              },
              "&:hover fieldset": {
                borderColor: "#D3D3D3",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#D3D3D3",
              },
            },
          }}
        />
        <Button
          variant={"contained"}
          sx={{
            background: "#826ee4",
            textTransform: "none",
            m: 1,
            "&:hover": {
              background: "#826ee",
            },
            alignSelf: "right",
          }}
          type={"submit"}
        >
          <Typography
            variant={"body1"}
            sx={{
              color: "white",
            }}
          >
            Submit
          </Typography>
        </Button>
      </form>

      <img
        src={isMobile ? poloroidsMobile : poloroids}
        alt="white-logo"
        style={{
          position: isMobile ? "static" : "absolute",
          zIndex: -1,
          width: isMobile ? "100%" : "100%",
          marginTop: isMobile ? "-80px" : "300px",
        }}
      />
    </Container>
  );
};

export default Campaign;

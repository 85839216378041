import { Typography } from "@mui/material";
import { Container } from "@mui/system";
import { FunctionComponent } from "react";
import logo from "../images/logo.png";
const Thankyou: FunctionComponent = () => {
  const isMobile = window.innerWidth <= 800;
  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <img
        src={logo}
        alt="white-logo"
        style={{
          width: isMobile ? 150 : 200,
          height: isMobile ? 100 : 180,
        }}
      />
      <Typography variant={"h1"}>
        Thank you for registering with YAASS!
      </Typography>
      <Typography variant={"body1"} sx={{
        textAlign: "center",
        m:1
      }}>
        An invitation email has been send to your registered email address.
      </Typography>
    </Container>
  );
};

export default Thankyou;

import { Container } from "@mui/system";
import logo from "../images/logo.png";

const CommunityGuidelines = () => {
  return (
    <Container>
      <img
        src={logo}
        alt="white-logo"
        style={{
          width: 200,
          height: 200,
          margin: "auto",
          padding: "0",
        }}
      />
      <div className="relative py-16 bg-white overflow-hidden">
        <div className="relative px-4 sm:px-6 lg:px-8">
          <div className="text-lg max-w-prose mx-auto">
            <h1>
              <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl lg:text-5xl">
                Community Guidelines{" "}
              </span>
            </h1>
          </div>
          <div className="mt-12 prose sm:max-w-screen-sm md:max-w-screen-md lg:max-w-screen-lg prose-indigo prose-lg text-gray-600 mx-auto">
            <div>
              <p>
                These Guidelines and our
                <a href="./terms-and-conditions.html">
                  YAASS Terms &amp; Conditions
                </a>{" "}
                are designed for every user’s safe and enjoyable experience on
                the YAASS platform. Failure to follow these guidelines may
                result in loss of access to our platform.
              </p>
              <h2>Upload only your own photos</h2>
              <p>
                We take copyright law very seriously. If you do not own the
                usage rights to a photo or video, please do not post it. Same
                goes for pets. If the pet isn’t yours– don’t post it… (just a
                joke!)
              </p>
              <h2>Respect all users</h2>
              <p>
                We are a community focused on diversity. This means you should
                respect other people’s beliefs, interests, and property while on
                YAASS. YAASS takes a strong stance against hate speech, rude or
                abusive behavior, bullying, and misogyny. The way you would
                behave on YAASS should be the same as you would in real life.
                Additionally, we encourage our users to report those who do not
                follow these behavioral guidelines. As a community with a
                foundation built upon kindness and respect, we expect respect
                from our users to others, themselves, and the YAASS staff.
              </p>
              <h2>Send original messages to other users</h2>
              <p>
                We advise against repeating the same message to each connection.
                We encourage our users to read profiles, learn of other people’s
                interests, and send an appropriate, relevant, and unique message
                to each match. We promise this will help increase your chances
                of engaging in interesting conversation with other users.
              </p>
              <h2>Don’t pretend you’re someone else</h2>
              <p>
                As stated previously, only post photos that are photos of you.
                We also recommend using our photo verification tool to help
                other users know the legitimacy of your profile.
              </p>
              <h2>
                YAASS content(photos, videos ,text, comments, chats, labels or
                any placeholder in YAASS) guidelines:
              </h2>
              <ol>
                <li>
                  No children on their own. They must be fully clothed along
                  with an adult in the photo.
                </li>
                <li>
                  No nudity or explicit content. Please refrain from using any
                  sexual or violent language.
                </li>
                <li>
                  No profinity or inappropriate language in posts, comments
                  ,chats or any content in YAASS.
                </li>
                <li>No pornographic material.</li>
                <li>No watermarks or overlaid text.</li>
                <li>No graphic hunting photos.</li>
                <li>No guns or dangerous weapons.</li>
                <li>Face must be clearly visible in all photos.</li>
              </ol>
              <h2>Abstain from Illegal Actions on our Site</h2>
              <p>
                YAASS will not tolerate illegal activity. This may result in
                being banned from the site, and/or being reported to the
                authorities.
              </p>
              <h2>No soliciting</h2>
              <p>
                YAASS is not a platform for selling products or services. You
                will be banned if you attempt to use it as a marketplace.
              </p>
              <p>
                <strong>
                  No children by themselves they must be fully clothed with an
                  adult in the photo
                </strong>
              </p>
              <p>
                YAASS is for 18+ years of age individuals, thus we do not allow
                kids to be alone in photos as representatives of their parents
                on YAASS. Additionally, all children must be completely clothed.
                This is for the safety of both children and their parents.
              </p>
              <p>
                We use a combination of our team of moderators and automated
                systems to review and monitor accounts and messages of content
                that indicates any breache(s) of these Guidelines and
                <a href="/pages/terms-and-conditions.html">YAASS Terms &amp; Conditions</a>
                . Not following these guidelines will result in rremoval of
                content or/and account suspension or removal at our discretion).
                These guidelines are designed to make a safe and friendly place
                for our users on YAASS.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default CommunityGuidelines;

import Card from '@mui/material/Card';
import { Container } from "@mui/system";
import { FunctionComponent } from "react";
import logo from "../images/logo.png";
import MenuIcon from '@mui/icons-material/Menu';
import SettingsIcon from '@mui/icons-material/Settings';
import DeleteIcon from '@mui/icons-material/Delete';
const DeleteYourAccount: FunctionComponent = () => {
  const isMobile = window.innerWidth <= 800;
  
  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <img
        src={logo}
        alt="white-logo"
        style={{
          width: isMobile ? 150 : 200,
          height: isMobile ? 100 : 180,
        }}
      />
      <div >
      <Card sx={{ flexGrow: 1 }} variant="outlined">
      <h5 style={{marginLeft:"15px",marginBottom:"30px"}}> 
      To delete your account, you would need to download and login to the mobile version of the YAASS app. 
        </h5>
      <h5 style={{marginLeft:"15px"}}> 
      When you delete your account, your profile, photos, videos, comments, likes, and connections will be permanently removed. For security reasons, we can't delete an account for you. You’ll need to be able to log into your account to request deletion. If you can't remember your password, please reset your password. Your chats sent to a group chat or a connection would not be deleted.
        </h5>
         
         <h4 style={{marginLeft:"15px"}}>  To request the permanent deletion of your account from your YAASS app:</h4>
      <ol>
      <li style={{display: "flex",alignItems: "center", marginBottom:"20px"}}> <div>1.  Tap the menu option  <MenuIcon  /> at the left top section of the app.</div></li>
      <li style={{display: "flex",alignItems: "center", marginBottom:"20px"}}> <div>2.  Select   <SettingsIcon /> Settings option from the list of options.</div></li>
      <li style={{display: "flex",alignItems: "center", marginBottom:"20px"}}> <div>3.  Scroll down and Tap <DeleteIcon /> Delete Account (Delete my account) option.</div></li>
      <li style={{display: "flex",alignItems: "center", marginBottom:"70px"}}> <div>4.  Confirm Yes to Delete Account option.</div></li>
      
      <li style={{display: "flex",alignItems: "center", marginBottom:"20px"}}> Please report any issues to support@yaass.com!</li>
       
      </ol>
    
        </Card>

        </div>
     
     
    </Container>
  );
};

export default DeleteYourAccount;

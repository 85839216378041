import { Container } from "@mui/material";
import logo from "../images/logo.png";

const PrivacyPolicy = () => {
  return (
    <Container>
      <div>
        <img
          src={logo}
          alt="white-logo"
          style={{
            width: 200,
            height: 200,
            margin: "auto",
          }}
        />
        <div>
          <div>
            <div className="text-lg max-w-prose mx-auto">
              <h1>
                <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl lg:text-5xl">
                  Privacy Policy
                </span>
                <br />
                <br />
                <span className="block text-sm pt-4 text-center text-gray-500 font-semibold tracking-wide uppercase">
                  Last updated: June 20, 2023
                </span>
              </h1>
            </div>
            <div>
              <p>
                This Privacy Policy describes Our policies and procedures on the
                collection, use and disclosure of Your information when You use
                the Service and tells You about Your privacy rights and how the
                law protects You.
              </p>
              <p>
                We use Your Personal data to provide and improve the Service. By
                using the Service, You agree to the collection and use of
                information in accordance with this Privacy Policy.
              </p>
              <h2>Interpretation and Definitions</h2>
              <h3>Interpretation</h3>
              <p>
                The words of which the initial letter is capitalized have
                meanings defined under the following conditions. The following
                definitions shall have the same meaning regardless of whether
                they appear in singular or in plural.
              </p>
              <h3>Definitions</h3>
              <p>For the purposes of this Privacy Policy:</p>
              <ol>
                <li>
                  Account means a unique account created for You to access our
                  Service or parts of our Service.
                </li>
                <li>
                  Affiliate means an entity that controls, is controlled by or
                  is under common control with a party, where “control” means
                  ownership of 50% or more of the shares, equity interest or
                  other securities entitled to vote for election of directors or
                  other managing authority.
                </li>
                <li>
                  Application means the software program provided by the Company
                  downloaded by You on any electronic device, named YAASS
                </li>
                <li>
                  Business, for the purpose of the CCPA (California Consumer
                  Privacy Act), refers to the Company as the legal entity that
                  collects Consumers’ personal information and determines the
                  purposes and means of the processing of Consumers’ personal
                  information, or on behalf of which such information is
                  collected and that alone, or jointly with others, determines
                  the purposes and means of the processing of consumers’
                  personal information, that does business in the State of
                  California.
                </li>
                <li>
                  Company (referred to as either “the Company”, “We”, “Us” or
                  “Our” in this Agreement) refers to YAASS, LLC, 55 E. Monroe
                  Street, Suite 3800 Chicago, Illinois, 60603.
                  <br />
                  For the purpose of the GDPR, the Company is the Data
                  Controller.
                </li>
                <li>
                  Consumer, for the purpose of the CCPA (California Consumer
                  Privacy Act), means a natural person who is a California
                  resident. A resident, as defined in the law, includes (1)
                  every individual who is in the USA for other than a temporary
                  or transitory purpose, and (2) every individual who is
                  domiciled in the USA who is outside the USA for a temporary or
                  transitory purpose.
                </li>
                <li>Country refers to: United States</li>
                <li>
                  Data Controller, for the purposes of the GDPR (General Data
                  Protection Regulation), refers to the Company as the legal
                  person which alone or jointly with others determines the
                  purposes and means of the processing of Personal Data.
                </li>
                <li>
                  Device means any device that can access the Service such as a
                  computer, a cellphone or a digital tablet.
                </li>
                <li>
                  Do Not Track (DNT) is a concept that has been promoted by US
                  regulatory authorities, in particular the U.S. Federal Trade
                  Commission (FTC), for the Internet industry to develop and
                  implement a mechanism for allowing internet users to control
                  the tracking of their online activities across websites.
                </li>
                <li>
                  Facebook Fan Page is a public profile named YAASS specifically
                  created by the Company on the Facebook social network,
                  accessible from
                  <a
                    href="https://www.facebook.com/YAASS-195108407636738/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://www.facebook.com/YAASS-195108407636738/
                  </a>
                </li>
                <li>
                  Personal Data is any information that relates to an identified
                  or identifiable individual.
                  <br />
                  For the purposes for GDPR, Personal Data means any information
                  relating to You such as a name, an identification number,
                  location data, online identifier or to one or more factors
                  specific to the physical, physiological, genetic, mental,
                  economic, cultural or social identity.
                  <br />
                  For the purposes of the CCPA, Personal Data means any
                  information that identifies, relates to, describes or is
                  capable of being associated with, or could reasonably be
                  linked, directly or indirectly, with You.
                </li>
                <li>
                  Sale, for the purpose of the CCPA (California Consumer Privacy
                  Act), means selling, renting, releasing, disclosing,
                  disseminating, making available, transferring, or otherwise
                  communicating orally, in writing, or by electronic or other
                  means, a Consumer’s personal information to another business
                  or a third party for monetary or other valuable consideration.
                </li>
                <li>Service refers to the Application.</li>
                <li>
                  Service Provider means any natural or legal person who
                  processes the data on behalf of the Company. It refers to
                  third-party companies or individuals employed by the Company
                  to facilitate the Service, to provide the Service on behalf of
                  the Company, to perform services related to the Service or to
                  assist the Company in analyzing how the Service is used. For
                  the purpose of the GDPR, Service Providers are considered Data
                  Processors.
                </li>
                <li>
                  Third-party Social Media Service refers to any website or any
                  social network website through which a User can log in or
                  create an account to use the Service.
                </li>
                <li>
                  Usage Data refers to data collected automatically, either
                  generated by the use of the Service or from the Service
                  infrastructure itself (for example, the duration of a page
                  visit).
                </li>
                <li>
                  You means the individual accessing or using the Service, or
                  the company, or other legal entity on behalf of which such
                  individual is accessing or using the Service, as applicable.
                  <br />
                  Under GDPR (General Data Protection Regulation), You can be
                  referred to as the Data Subject or as the User as you are the
                  individual using the Service.
                </li>
              </ol>
              <h2>Collecting and Using Your Personal Data</h2>
              <h3>Types of Data Collected</h3>
              <h4>Personal Data</h4>
              <p>
                While using Our Service, We may ask You to provide Us with
                certain personally identifiable information that can be used to
                contact or identify You. Personally identifiable information may
                include, but is not limited to:
              </p>
              <ol>
                <li>Email address</li>
                <li>First name and last name</li>
                <li>Phone number</li>
                <li>Birth Place</li>
                <li>Date of Birth</li>
                <li>Location/Current City</li>
                <li>Gender</li>
                <li>Orientation</li>
                <li>Religion</li>
                <li>Relationship Status</li>
                <li>Professional details – work and education</li>
                <li>Places already been and want to go</li>
                <li>Interests and hobbies</li>
                <li>State, Province, , City</li>
                <li>
                  Bank account information in order to pay for products and/or
                  services within the Service
                </li>
                <li>Usage Data</li>
              </ol>
              <p>
                When You pay for a product and/or a service via bank transfer,
                We may ask You to provide information to facilitate this
                transaction and to verify Your identity. Such information may
                include, without limitation: Stored with end-to-end encryption,
                some personal and encrypted data is stored. This provides data
                security at the highest level. A key protects the data, which is
                derived from information unique to only your device in addition
                to your device passcode. This is protection only you will know
              </p>
              <ol>
                <li>Date of birth</li>
                <li>Bank card statement</li>
                <li>
                  Other information linking You to an address to prove bank
                  account details
                </li>
              </ol>
              <h4>Usage Data</h4>
              <p>
                Usage Data is collected automatically when using the Service.
              </p>
              <p>
                Usage Data may include information such as Your Device’s
                Internet Protocol address (e.g. IP address), browser type,
                browser version, the pages of our Service that You visit, the
                time and date of Your visit, the time spent on those pages,
                unique device identifiers and other diagnostic data.
              </p>
              <p>
                When You access the Service by or through a mobile device, We
                may collect certain information automatically, including, but
                not limited to, the type of mobile device You use, Your mobile
                device unique ID, the IP address of Your mobile device, Your
                mobile operating system, the type of mobile Internet browser You
                use, unique device identifiers and other diagnostic data.
              </p>
              <p>
                We may also collect information that Your browser sends whenever
                You visit our Service or when You access the Service by or
                through a mobile device.
              </p>
              <h4>Information from Third-Party Social Media Services</h4>
              <p>
                The Company allows You to create an account and log in to use
                the Service through the following Third-party Social Media
                Services:
              </p>
              <ol>
                <li>Google</li>
                <li>Facebook</li>
                <li>Twitter</li>
                <li>Others if applicable</li>
              </ol>
              <p>
                If You decide to register through or otherwise grant us access
                to a Third-Party Social Media Service, We may collect Personal
                data that is already associated with Your Third-Party Social
                Media Service’s account, such as Your name, Your email address,
                Your activities or Your contact list associated with that
                account.
              </p>
              <p>
                You may also have the option of sharing additional information
                with the Company through Your Third-Party Social Media Service’s
                account. If You choose to provide such information and Personal
                Data, during registration or otherwise, You are giving the
                Company permission to use, share, and store it in a manner
                consistent with this Privacy Policy.
              </p>
              <h4>Information Collected while Using the Application</h4>
              <p>
                While using Our Application, in order to provide features of Our
                Application, We may collect, with Your prior permission:
              </p>
              <ol>
                <li>Information regarding your location</li>
                <li>
                  Information from your Device’s phone book (contacts list)
                </li>
                <li>
                  Pictures and other information from your Device’s camera and
                  photo &amp; video library
                </li>
              </ol>
              <p>
                We use this information to provide features of Our Service, to
                improve and customize Our Service. The information may be
                uploaded to the Company’s servers and/or a Service Provider’s
                server or it may be simply stored on Your device.
              </p>
              <p>
                You can enable or disable access to this information at any
                time, through Your Device settings.
              </p>
              <h3>Use of Your Personal Data</h3>
              <p>
                The Company may use Personal Data for the following purposes:
              </p>
              <ol>
                <li>
                  To provide and maintain our Service, including to monitor the
                  usage of our Service.
                </li>
                <li>
                  To manage Your Account: to manage Your registration as a user
                  of the Service. The Personal Data You provide can give You
                  access to different functionalities of the Service that are
                  available to You as a registered user.
                </li>
                <li>
                  For the performance of a contract: the development, compliance
                  and undertaking of the purchase contract for the products,
                  items or services You have purchased or of any other contract
                  with Us through the Service.
                </li>
                <li>
                  To contact You: To contact You by email, telephone calls, SMS,
                  or other equivalent forms of electronic communication, such as
                  a mobile application’s push notifications regarding updates or
                  informative communications related to the functionalities,
                  products or contracted services, including the security
                  updates, when necessary or reasonable for their
                  implementation.
                </li>
                <li>
                  To provide You with news, special offers and general
                  information about other goods, services and events which we
                  offer that are similar to those that you have already
                  purchased or enquired about unless You have opted not to
                  receive such information.
                </li>
                <li>
                  To manage Your requests: To attend and manage Your requests to
                  Us.
                </li>
                <li>
                  For business transfers: We may use Your information to
                  evaluate or conduct a merger, divestiture, restructuring,
                  reorganization, dissolution, or other sale or transfer of some
                  or all of Our assets, whether as a going concern or as part of
                  bankruptcy, liquidation, or similar proceeding, in which
                  Personal Data held by Us about our Service users is among the
                  assets transferred.
                </li>
                <li>
                  For other purposes: We may use Your information for other
                  purposes, such as data analysis, identifying usage trends,
                  determining the effectiveness of our promotional campaigns and
                  to evaluate and improve our Service, products, services,
                  marketing and your experience.
                </li>
              </ol>
              <p>
                We may share Your personal information in the following
                situations:
              </p>
              <ol>
                <li>
                  With Service Providers: We may share Your personal information
                  with Service Providers to monitor and analyze the use of our
                  Service, to show advertisements to You to help support and
                  maintain Our Service, for payment processing, to contact You.
                </li>
                <li>
                  For business transfers: We may share or transfer Your personal
                  information in connection with, or during negotiations of, any
                  merger, sale of Company assets, financing, or acquisition of
                  all or a portion of Our business to another company.
                </li>
                <li>
                  With Affiliates: We may share Your information with Our
                  affiliates, in which case we will require those affiliates to
                  honor this Privacy Policy. Affiliates include Our parent
                  company and any other subsidiaries, joint venture partners or
                  other companies that We control or that are under common
                  control with Us.
                </li>
                <li>
                  With business partners: We may share Your information with Our
                  business partners to offer You certain products, services or
                  promotions.
                </li>
                <li>
                  With other users: when You share personal information or
                  otherwise interact in the public areas with other users, such
                  information may be viewed by all users and may be publicly
                  distributed outside. If You interact with other users or
                  register through a Third-Party Social Media Service, Your
                  contacts on the Third-Party Social Media Service may see Your
                  name, profile, pictures and description of Your activity.
                  Similarly, other users will be able to view descriptions of
                  Your activity, communicate with You and view Your profile.
                </li>
                <li>
                  With Your consent: We may disclose Your personal information
                  for any other purpose with Your consent.
                </li>
                <li>
                  Other third-party Service providers if applicable maybe added
                  in the future
                </li>
              </ol>
              <h3>Retention of Your Personal Data</h3>
              <p>
                The Company will retain Your Personal Data only for as long as
                is necessary for the purposes set out in this Privacy Policy. We
                will retain and use Your Personal Data to the extent necessary
                to comply with our legal obligations (for example, if we are
                required to retain your data to comply with applicable laws),
                resolve disputes, and enforce our legal agreements and policies.
              </p>
              <p>
                The Company will also retain Usage Data for internal analysis
                purposes. Usage Data is generally retained for a shorter period
                of time, except when this data is used to strengthen the
                security or to improve the functionality of Our Service, or We
                are legally obligated to retain this data for longer time
                periods.
              </p>
              <h3>Transfer of Your Personal Data</h3>
              <p>
                Your information, including Personal Data, is processed at the
                Company’s operating offices and in any other places where the
                parties involved in the processing are located. It means that
                this information may be transferred to — and maintained on —
                computers located outside of Your state, province, country or
                other governmental jurisdiction where the data protection laws
                may differ than those from Your jurisdiction.
              </p>
              <p>
                Your consent to this Privacy Policy followed by Your submission
                of such information represents Your agreement to that transfer.
              </p>
              <p>
                The Company will take all steps reasonably necessary to ensure
                that Your data is treated securely and in accordance with this
                Privacy Policy and no transfer of Your Personal Data will take
                place to an organization or a country unless there are adequate
                controls in place including the security of Your data and other
                personal information.
              </p>
              <h3>Disclosure of Your Personal Data</h3>
              <h4>Business Transactions</h4>
              <p>
                If the Company is involved in a merger, acquisition or asset
                sale, Your Personal Data may be transferred. We will provide
                notice before Your Personal Data is transferred and becomes
                subject to a different Privacy Policy.
              </p>
              <h4>Law enforcement</h4>
              <p>
                Under certain circumstances, the Company may be required to
                disclose Your Personal Data if required to do so by law or in
                response to valid requests by public authorities (e.g. a court
                or a government agency).
              </p>
              <h4>Other legal requirements</h4>
              <p>
                The Company may disclose Your Personal Data in the good faith
                belief that such action is necessary to:
              </p>
              <ol>
                <li>Comply with a legal obligation</li>
                <li>
                  Protect and defend the rights or property of the Company
                </li>
                <li>
                  Prevent or investigate possible wrongdoing in connection with
                  the Service
                </li>
                <li>
                  Protect the personal safety of Users of the Service or the
                  public
                </li>
                <li>Protect against legal liability</li>
              </ol>
              <h3>Security of Your Personal Data</h3>
              <p>
                The security of Your Personal Data is important to Us, but
                remember that no method of transmission over the Internet, or
                method of electronic storage is 100% secure. While We strive to
                use commercially acceptable means to protect Your Personal Data,
                We cannot guarantee its absolute security.
              </p>
              <h2>
                Detailed Information on the Processing of Your Personal Data
              </h2>
              <p>
                The Service Providers We use may have access to Your Personal
                Data. These third-party vendors collect, store, use, process and
                transfer information about Your activity on Our Service in
                accordance with their Privacy Policies.
              </p>
              <h3>Analytics</h3>
              <p>
                We may use third-party Service providers to monitor and analyze
                the use of our Service.
              </p>
              <ol>
                <li>
                  <span className="font-semibold block">Google Analytics</span>
                  Google Analytics is a web analytics service offered by Google
                  that tracks and reports website traffic. Google uses the data
                  collected to track and monitor the use of our Service. This
                  data is shared with other Google services. Google may use the
                  collected data to contextualize and personalize the ads of its
                  own advertising network.
                  <br />
                  You may opt-out of certain Google Analytics features through
                  your mobile device settings, such as your device advertising
                  settings or by following the instructions provided by Google
                  in their Privacy Policy:
                  <a
                    href="https://policies.google.com/privacy"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://policies.google.com/privacy
                    <br />
                  </a>
                  For more information on the privacy practices of Google,
                  please visit the Google Privacy &amp; Terms web page:
                  <a
                    href="https://policies.google.com/privacy"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://policies.google.com/privacy
                  </a>
                </li>
                <li>
                  <span>Firebase</span>Firebase is an analytics service provided
                  by Google Inc.
                  <br />
                  You may opt-out of certain Firebase features through your
                  mobile device settings, such as your device advertising
                  settings or by following the instructions provided by Google
                  in their Privacy Policy:
                  <a
                    href="https://policies.google.com/privacy"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://policies.google.com/privacy
                    <br />
                  </a>
                  We also encourage you to review the Google’s policy for
                  safeguarding your data:
                  <a
                    href="https://support.google.com/analytics/answer/6004245"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://support.google.com/analytics/answer/6004245
                    <br />
                  </a>
                  For more information on what type of information Firebase
                  collects, please visit the How Google uses data when you use
                  our partners’ sites or apps webpage:
                  <a
                    href="https://policies.google.com/technologies/partner-sites"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://policies.google.com/technologies/partner-sites
                  </a>
                </li>
                <li>
                  <span>Matomo</span>Matomo is a web analytics service. You can
                  visit their Privacy Policy page here:{" "}
                  <a
                    href="https://matomo.org/privacy-policy"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://matomo.org/privacy-policy
                  </a>
                </li>
                <li>
                  <span>Clicky</span>Clicky is a web analytics service. Read the
                  Privacy Policy for Clicky here:
                  <a
                    href="https://clicky.com/terms"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://clicky.com/terms
                  </a>
                </li>
                <li>
                  <span>Statcounter</span>
                  Statcounter is a web traffic analysis tool. You can read the
                  Privac Policy for Statcounter here:
                  <a
                    href="https://statcounter.com/about/legal/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://statcounter.com/about/legal/
                  </a>
                </li>
                <li>
                  <span>Flurry Analytics</span>
                  Flurry Analytics service is provided by Yahoo! Inc.
                  <br />
                  You can opt-out from Flurry Analytics service to prevent
                  Flurry Analytics from using and sharing your information by
                  visiting the Flurry’s Opt-out page:
                  <a
                    href="https://developer.yahoo.com/flurry/end-user-opt-out/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://developer.yahoo.com/flurry/end-user-opt-out/
                    <br />
                  </a>
                  For more information on the privacy practices and policies of
                  Yahoo!, please visit their Privacy Policy page:
                  <a
                    href="https://policies.yahoo.com/xa/en/yahoo/privacy/index.htm"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://policies.yahoo.com/xa/en/yahoo/privacy/index.htm
                  </a>
                </li>
                <li>
                  <span>Mixpanel</span>Mixpanel is provided by Mixpanel Inc.
                  <br />
                  You can prevent Mixpanel from using your information for
                  analytics purposes by opting-out. To opt-out of Mixpanel
                  service, please visit this page:
                  <a
                    href="https://mixpanel.com/optout/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://mixpanel.com/optout/
                    <br />
                  </a>
                  For more information on what type of information Mixpanel
                  collects, please visit the Terms of Use page of Mixpanel:{" "}
                  <a href="https://mixpanel.com/terms/">
                    https://mixpanel.com/terms/
                  </a>
                </li>
                <li>
                  <span>Unity Analytics</span>Unity Analytics is provided by
                  Unity Technologies.
                  <br />
                  Unity Analytics collects, stores, uses, processes, and
                  transfers personal information to provide, operate, and
                  improve Unity Technologies services, including personalized
                  advertising services. For more information on what type of
                  information Unity Technologies collects, please visit their
                  Privacy Policy page:
                  <a
                    href="https://unity3d.com/legal/privacy-policy"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://unity3d.com/legal/privacy-policy
                  </a>
                  .<br />
                  You can prevent Unity Analytics from using your information by
                  opting-out. To opt-out of Unity Analytics, please visit this
                  page:
                  <a
                    href="https://unity3d.com/legal/privacy-policy"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://unity3d.com/legal/privacy-policy
                    <br />
                  </a>
                  You may opt-out of all personalized advertising by enabling
                  privacy features on Your mobile device such as Limit Ad
                  Tracking (iOS) and Opt Out of Ads Personalization (Android).
                  See Your mobile device Help system for more information.
                </li>
                <li>Other third-party Service providers</li>
              </ol>
              <h3>Advertising</h3>
              <p>
                We may use Service Providers to show advertisements to You to
                help support and maintain Our Service.
              </p>
              <ol>
                <li>
                  <span>Google AdSense &amp; DoubleClick Cookie</span>
                  Google, as a third party vendor, uses cookies to serve ads on
                  our Service. Google’s use of the DoubleClick cookie enables it
                  and its partners to serve ads to our users based on their
                  visit to our Service or other websites on the Internet.
                  <br />
                  You may opt out of the use of the DoubleClick Cookie for
                  interest-based advertising by visiting the Google Ads Settings
                  web page:
                  <a
                    href="http://www.google.com/ads/preferences/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    http://www.google.com/ads/preferences/
                  </a>
                </li>
                <li>
                  <span>AdMob by Google</span>AdMob by Google is provided by
                  Google Inc.
                  <br />
                  You can opt-out from the AdMob by Google service by following
                  the instructions described by Google:
                  <a
                    href="https://support.google.com/ads/answer/2662922?hl=en"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://support.google.com/ads/answer/2662922?hl=en
                    <br />
                  </a>
                  For more information on how Google uses the collected
                  information, please visit the “How Google uses data when you
                  use our partners’ sites or app” page:
                  <a
                    href="https://policies.google.com/technologies/partner-sites"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://policies.google.com/technologies/partner-sites
                  </a>
                  or visit the Privacy Policy of Google:
                  <a
                    href="https://policies.google.com/privacy"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://policies.google.com/privacy
                  </a>
                </li>
                <li>
                  <span>Bing Ads</span>Bing Ads is an advertising service
                  provided by Microsoft Inc.
                  <br />
                  You can opt-out from Bing Ads by following the instructions on
                  Bing Ads Opt-out page:
                  <a
                    href="https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads
                    <br />
                  </a>
                  For more information about Bing Ads, please visit their
                  Privacy Policy:
                  <a
                    href="https://privacy.microsoft.com/en-us/PrivacyStatement"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://privacy.microsoft.com/en-us/PrivacyStatement
                  </a>
                </li>
                <li>
                  <span>Flurry</span>Flurry is provided by Yahoo! Inc.
                  <br />
                  You can opt-out of the Flurry service and prevent it from
                  using and sharing your information by visiting the Flurry
                  Opt-out page:
                  <a
                    href="https://developer.yahoo.com/flurry/end-user-opt-out/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://developer.yahoo.com/flurry/end-user-opt-out/
                    <br />
                  </a>
                  For more information on the privacy practices policies of
                  Yahoo!, please visit their Privacy Policy:
                  <a
                    href="https://policies.yahoo.com/xa/en/yahoo/privacy/index.htm"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://policies.yahoo.com/xa/en/yahoo/privacy/index.htm
                  </a>
                </li>
                <li>
                  <span>InMobi</span>InMobi is provided by InMobi Pte Ltd.
                  <br />
                  You can opt-out from InMobi service by following the
                  instructions as described on the InMobi Opt-out page:
                  <a
                    href="http://www.inmobi.com/page/opt-out/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    http://www.inmobi.com/page/opt-out/
                    <br />
                  </a>
                  For more information on the privacy practices and policies of
                  InMobi, please visit the InMobi Privacy Policy:
                  <a
                    href="http://www.inmobi.com/privacy-policy/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    http://www.inmobi.com/privacy-policy/
                  </a>
                </li>
                <li>
                  <span>MoPub</span>MoPub is provided by MobPub Inc.
                  <br />
                  You can opt-out from MobPub service by following the
                  instructions on MoPub Opt-out page:
                  <a
                    href="http://www.mopub.com/optout/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    http://www.mopub.com/optout/
                    <br />
                  </a>
                  For more information on what information MobPub collects and
                  how it is used, please read MoPub Privacy Policy:
                  <a
                    href="http://www.mopub.com/legal/privacy/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    http://www.mopub.com/legal/privacy/
                  </a>
                </li>
                <li>
                  <span>StartApp</span>StartApp is provided by StartApp Inc.
                  <br />
                  You can opt-out from interest-based ads served by StartApp by
                  following the instructions on their Privacy Policy, section
                  “8. Your Choices and Controls”:
                  <a
                    href="https://www.startapp.com/policy/privacy-policy/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://www.startapp.com/policy/privacy-policy/
                    <br />
                  </a>
                  For more information on what information StartApp collects and
                  how it is used, please read their Privacy Policy:
                  <a
                    href="https://www.startapp.com/policy/privacy-policy/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://www.startapp.com/policy/privacy-policy/
                  </a>
                </li>
                <li>
                  <span>AdColony</span>AdColony is provided by Jirbo Inc.
                  <br />
                  You can opt-out from AdColony service by visiting the AdColony
                  Opt-out page:
                  <a
                    href="https://www.adcolony.com/privacy-policy/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://www.adcolony.com/privacy-policy/
                    <br />
                  </a>
                  You can also opt-out from AdColony through the Digital
                  Advertising Alliance in the USA
                  <a href="http://www.aboutads.info/choices/">
                    http://www.aboutads.info/choices/
                  </a>{" "}
                  or opt-out using your mobile device settings.
                  <br />
                  For more information AdColony, please visit the Privacy Policy
                  of AdColony:
                  <a
                    href="http://www.adcolony.com/privacy-policy/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    http://www.adcolony.com/privacy-policy/
                  </a>
                </li>
                <li>
                  <span>AppLovin</span>AppLovin is provided by the AppLovin
                  Corporation.
                  <br />
                  If you’d like to opt-out from AppLovin service, please visit
                  the AppLovin Opt-out page:
                  <a
                    href="https://www.applovin.com/optout"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://www.applovin.com/optout
                    <br />
                  </a>
                  If you’d like to learn more about AppLovin, please visit the
                  Privacy Policy page of AppLovin:
                  <a
                    href="https://www.applovin.com/privacy"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://www.applovin.com/privacy
                  </a>
                </li>
                <li>
                  <span>Vungle</span>Vungle is provided by Vungle Inc.
                  <br />
                  You can opt-out from Vungle service by following the
                  instructions as described by Vungle on their Privacy Policy
                  page:
                  <a
                    href="http://vungle.com/privacy/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    http://vungle.com/privacy/
                    <br />
                  </a>
                  For more information about Vungle, please visit Vungle Privacy
                  Policy:
                  <a
                    href="http://vungle.com/privacy/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    http://vungle.com/privacy/
                  </a>
                </li>
                <li>
                  AdButler
                  <br />
                  AdButler is an advertising service provided by Sparklit
                  Networks Inc.
                  <br />
                  For more information about AdButler, please visit their
                  Privacy Policy:
                  <a
                    href="https://www.sparklit.com/agreements.spark?agreement=privacy"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://www.sparklit.com/agreements.spark?agreement=privacy
                  </a>
                </li>
                <li>
                  Unity Ads
                  <br />
                  Unity Ads is provided by Unity Technologies.
                  <br />
                  Unity Ads collects, stores, uses, processes, and transfers
                  personal information to provide, operate, and improve Unity
                  Technologies services, including personalized advertising
                  services. For more information on what type of information
                  Unity Technologies collects, please visit their Privacy Policy
                  page:
                  <a
                    href="https://unity3d.com/legal/privacy-policy"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://unity3d.com/legal/privacy-policy
                  </a>
                  .<br />
                  You can prevent Unity Ads from using your information by
                  opting-out. To opt-out of Unity Analytics, please visit this
                  page:
                  <a
                    href="https://unity3d.com/legal/privacy-policy"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://unity3d.com/legal/privacy-policy
                    <br />
                  </a>
                  You may opt-out of all personalized advertising by enabling
                  privacy features on Your mobile device such as Limit Ad
                  Tracking (iOS) and Opt Out of Ads Personalization (Android).
                  See Your mobile device Help system for more information.
                </li>
                <li>
                  Other Service Providers to show advertisements if applicable
                </li>
              </ol>
              <h3>Email Marketing</h3>
              <p>
                We may use Your Personal Data to contact You with newsletters,
                marketing or promotional materials and other information that
                may be of interest to You. You may opt-out of receiving any, or
                all, of these communications from Us by following the
                unsubscribe link or instructions provided in any email We send
                or by contacting Us.
              </p>
              <p>
                We may use Email Marketing Service Providers to manage and send
                emails to You.
              </p>
              <ol>
                <li>
                  Mailchimp
                  <br />
                  Mailchimp is an email marketing sending service provided by
                  The Rocket Science Group LLC.
                  <br />
                  For more information on the privacy practices of Mailchimp,
                  please visit their Privacy policy:
                  <a
                    href="https://mailchimp.com/legal/privacy/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://mailchimp.com/legal/privacy/
                  </a>
                </li>
                <li>
                  AWeber
                  <br />
                  AWeber is an email marketing sending service provided by
                  AWeber Communications.
                  <br />
                  For more information on the privacy practices of AWeber,
                  please visit their Privacy policy:
                  <a
                    href="https://www.aweber.com/privacy.htm"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://www.aweber.com/privacy.htm
                  </a>
                </li>
                <li>
                  GetResponse
                  <br />
                  GetResponse is an email marketing sending service provided by
                  GetResponse.
                  <br />
                  For more information on the privacy practices of GetResponse,
                  please visit their Privacy policy:
                  <a
                    href="https://www.getresponse.com/legal/privacy.html"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://www.getresponse.com/legal/privacy.html
                  </a>
                </li>
                <li>Other Email Marketing Service Providers</li>
              </ol>
              <h3>Payments</h3>
              <p>
                We may provide paid products and/or services within the Service.
                In that case, we may use third-party services for payment
                processing (e.g. payment processors).
              </p>
              <p>
                We will not store or collect Your payment card details. That
                information is provided directly to Our third-party payment
                processors whose use of Your personal information is governed by
                their Privacy Policy. These payment processors adhere to the
                standards set by PCI-DSS as managed by the PCI Security
                Standards Council, which is a joint effort of brands like Visa,
                Mastercard, American Express and Discover. PCI-DSS requirements
                help ensure the secure handling of payment information.
              </p>
              <ol>
                <li>
                  Apple Store In-App Payments
                  <br />
                  Their Privacy Policy can be viewed at
                  <a
                    href="https://www.apple.com/legal/privacy/en-ww/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://www.apple.com/legal/privacy/en-ww/
                  </a>
                </li>
                <li>
                  Google Play In-App Payments
                  <br />
                  Their Privacy Policy can be viewed at
                  <a
                    href="https://www.google.com/policies/privacy/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://www.google.com/policies/privacy/
                  </a>
                </li>
                <li>
                  Stripe
                  <br />
                  Their Privacy Policy can be viewed at
                  <a
                    href="https://stripe.com/us/privacy"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://stripe.com/us/privacy
                  </a>
                </li>
                <li>
                  WePay
                  <br />
                  Their Privacy Policy can be viewed at
                  <a
                    href="https://go.wepay.com/privacy-policy"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://go.wepay.com/privacy-policy
                  </a>
                </li>
                <li>
                  WorldPay
                  <br />
                  Their Privacy Policy can be viewed at
                  <a
                    href="https://www.worldpay.com/en-gb/privacy-policy"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://www.worldpay.com/en-gb/privacy-policy
                  </a>
                </li>
                <li>
                  PayPal
                  <br />
                  Their Privacy Policy can be viewed at
                  <a
                    href="https://www.paypal.com/webapps/mpp/ua/privacy-full"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://www.paypal.com/webapps/mpp/ua/privacy-full
                  </a>
                </li>
                <li>
                  Braintree
                  <br />
                  Their Privacy Policy can be viewed at
                  <a
                    href="https://www.braintreepayments.com/legal/braintree-privacy-policy"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://www.braintreepayments.com/legal/braintree-privacy-policy
                  </a>
                </li>
                <li>
                  FastSpring
                  <br />
                  Their Privacy Policy can be viewed at
                  <a
                    href="http://fastspring.com/privacy/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    http://fastspring.com/privacy/
                  </a>
                </li>
                <li>
                  net
                  <br />
                  Their Privacy Policy can be viewed at
                  <a
                    href="https://www.authorize.net/company/privacy/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://www.authorize.net/company/privacy/
                  </a>
                </li>
                <li>
                  2Checkout
                  <br />
                  Their Privacy Policy can be viewed at
                  <a
                    href="https://www.2checkout.com/policies/privacy-policy"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://www.2checkout.com/policies/privacy-policy
                  </a>
                </li>
                <li>
                  Sage Pay
                  <br />
                  Their Privacy Policy can be viewed at
                  <a
                    href="https://www.sagepay.co.uk/policies"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://www.sagepay.co.uk/policies
                  </a>
                </li>
                <li>
                  Square
                  <br />
                  Their Privacy Policy can be viewed at
                  <a
                    href="https://squareup.com/legal/privacy-no-account"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://squareup.com/legal/privacy-no-account
                  </a>
                </li>
                <li>
                  Go Cardless
                  <br />
                  Their Privacy Policy can be viewed at
                  <a
                    href="https://gocardless.com/en-eu/legal/privacy/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://gocardless.com/en-eu/legal/privacy/
                  </a>
                </li>
                <li>
                  Elavon
                  <br />
                  Their Privacy Policy can be viewed at
                  <a
                    href="https://www.elavon.com/privacy-pledge.html"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://www.elavon.com/privacy-pledge.html
                  </a>
                </li>
                <li>
                  Verifone
                  <br />
                  Their Privacy Policy can be viewed at
                  <a
                    href="https://www.verifone.com/en/us/legal"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://www.verifone.com/en/us/legal
                  </a>
                </li>
                <li>
                  Moneris
                  <br />
                  Their Privacy Policy can be viewed at
                  <a
                    href="https://www.moneris.com/en/Privacy-Policy"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://www.moneris.com/en/Privacy-Policy
                  </a>
                </li>
                <li>
                  WeChat
                  <br />
                  Their Privacy Policy can be viewed at
                  <a
                    href="https://www.wechat.com/en/privacy_policy.html"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://www.wechat.com/en/privacy_policy.html
                  </a>
                </li>
                <li>
                  Alipay
                  <br />
                  Their Privacy Policy can be viewed at
                  <a
                    href="https://render.alipay.com/p/f/agreementpages/alipayglobalprivacypolicy.html"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://render.alipay.com/p/f/agreementpages/alipayglobalprivacypolicy.html
                  </a>
                </li>
                <li>
                  Other third-party services for payment processing maybe added
                  in the future if applicable
                </li>
              </ol>
              <p>
                When You use Our Service to pay a product and/or service via
                bank transfer, We may ask You to provide information to
                facilitate this transaction and to verify Your identity.
              </p>
              <h3>Usage, Performance and Miscellaneous</h3>
              <p>
                We may use third-party Service Providers to provide better
                improvement of our Service.
              </p>
              <ol>
                <li>
                  Mouseflow
                  <br />
                  Mouseflow is a session replay and heatmap tool that shows how
                  visitors click, move, scroll, browse, and pay attention on
                  websites. The service is operated by ApS.
                  <br />
                  Mouseflow service may collect information from Your device.
                  <br />
                  The information gathered by Mouseflow is held in accordance
                  with its Privacy Policy:
                  <a
                    href="https://mouseflow.com/privacy/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://mouseflow.com/privacy/
                  </a>
                </li>
                <li>
                  FreshDesk
                  <br />
                  FreshDesk is a customer support software. The service is
                  operated by Freshworks, Inc.
                  <br />
                  FreshDesk service may collect information from Your Device.
                  <br />
                  The information gathered by FreshDesk is held in accordance
                  with its Privacy Policy:
                  <a
                    href="https://www.freshworks.com/privacy/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://www.freshworks.com/privacy/
                  </a>
                </li>
                <li>
                  Google Places
                  <br />
                  Google Places is a service that returns information about
                  places using HTTP requests. It is operated by Google
                  <br />
                  Google Places service may collect information from You and
                  from Your Device for security purposes.
                  <br />
                  The information gathered by Google Places is held in
                  accordance with the Privacy Policy of Google:
                  <a
                    href="https://www.google.com/intl/en/policies/privacy/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://www.google.com/intl/en/policies/privacy/
                  </a>
                </li>
                <li>
                  Other third-party Service Providers to provide better
                  improvement of our Service maybe added in the future if
                  applicable
                </li>
              </ol>
              <h2>GDPR Privacy</h2>
              <h3>Legal Basis for Processing Personal Data under GDPR</h3>
              <p>
                We may process Personal Data under the following conditions:
              </p>
              <ol>
                <li>
                  Consent: You have given Your consent for processing Personal
                  Data for one or more specific purposes.
                </li>
                <li>
                  Performance of a contract: Provision of Personal Data is
                  necessary for the performance of an agreement with You and/or
                  for any pre-contractual obligations thereof.
                </li>
                <li>
                  Legal obligations: Processing Personal Data is necessary for
                  compliance with a legal obligation to which the Company is
                  subject.
                </li>
                <li>
                  Vital interests: Processing Personal Data is necessary in
                  order to protect Your vital interests or of another natural
                  person.
                </li>
                <li>
                  Public interests: Processing Personal Data is related to a
                  task that is carried out in the public interest or in the
                  exercise of official authority vested in the Company.
                </li>
                <li>
                  Legitimate interests: Processing Personal Data is necessary
                  for the purposes of the legitimate interests pursued by the
                  Company.
                </li>
              </ol>
              <p>
                In any case, the Company will gladly help to clarify the
                specific legal basis that applies to the processing, and in
                particular whether the provision of Personal Data is a statutory
                or contractual requirement, or a requirement necessary to enter
                into a contract.
              </p>
              <h3>Your Rights under the GDPR</h3>
              <p>
                The Company undertakes to respect the confidentiality of Your
                Personal Data and to guarantee You can exercise Your rights.
              </p>
              <p>
                You have the right under this Privacy Policy, and by law if You
                are within the EU, to:
              </p>
              <ol>
                <li>
                  Request access to Your Personal Data. The right to access,
                  update or delete the information We have on You. Whenever made
                  possible, you can access, update or request deletion of Your
                  Personal Data directly within Your account settings section.
                  If you are unable to perform these actions yourself, please
                  contact Us to assist You. This also enables You to receive a
                  copy of the Personal Data We hold about You.
                </li>
                <li>
                  Request correction of the Personal Data that We hold about
                  You. You have the right to to have any incomplete or
                  inaccurate information We hold about You corrected.
                </li>
                <li>
                  Object to processing of Your Personal Data. This right exists
                  where We are relying on a legitimate interest as the legal
                  basis for Our processing and there is something about Your
                  particular situation, which makes You want to object to our
                  processing of Your Personal Data on this ground. You also have
                  the right to object where We are processing Your Personal Data
                  for direct marketing purposes.
                </li>
                <li>
                  Request erasure of Your Personal Data. You have the right to
                  ask Us to delete or remove Personal Data when there is no good
                  reason for Us to continue processing it.
                </li>
                <li>
                  Request the transfer of Your Personal Data. We will provide to
                  You, or to a third-party You have chosen, Your Personal Data
                  in a structured, commonly used, machine-readable format.
                  Please note that this right only applies to automated
                  information which You initially provided consent for Us to use
                  or where We used the information to perform a contract with
                  You.
                </li>
                <li>
                  Withdraw Your consent. You have the right to withdraw Your
                  consent on using your Personal Data. If You withdraw Your
                  consent, We may not be able to provide You with access to
                  certain specific functionalities of the Service.
                </li>
              </ol>
              <h3>Exercising of Your GDPR Data Protection Rights</h3>
              <p>
                You may exercise Your rights of access, rectification,
                cancellation and opposition by contacting Us. Please note that
                we may ask You to verify Your identity before responding to such
                requests. If You make a request, We will try our best to respond
                to You as soon as possible.
              </p>
              <p>
                You have the right to complain to a Data Protection Authority
                about Our collection and use of Your Personal Data. For more
                information, if You are in the European Economic Area (EEA),
                please contact Your local data protection authority in the EEA.
              </p>
              <h2>Facebook Fan Page</h2>
              <h3>Data Controller for the Facebook Fan Page</h3>
              <p>
                The Company is the Data Controller of Your Personal Data
                collected while using the Service. As operator of the Facebook
                Fan Page
                <a
                  href="https://www.facebook.com/YAASS-195108407636738/"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://www.facebook.com/YAASS-195108407636738/
                </a>
                , the Company and the operator of the social network Facebook
                are Joint Controllers.
              </p>
              <p>
                The Company has entered into agreements with Facebook that
                define the terms for use of the Facebook Fan Page, among other
                things. These terms are mostly based on the Facebook Terms of
                Service:{" "}
                <a
                  href="https://www.facebook.com/terms.php"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://www.facebook.com/terms.php
                </a>
              </p>
              <p>
                Visit the Facebook Privacy Policy
                <a
                  href="https://www.facebook.com/policy.php"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://www.facebook.com/policy.php
                </a>{" "}
                for more information about how Facebook manages Personal data or
                contact Facebook online, or by mail: Facebook, Inc. ATTN,
                Privacy Operations, 1601 Willow Road, Menlo Park, CA 94025,
                United States.
              </p>
              <h3>Facebook Insights</h3>
              <p>
                We use the Facebook Insights function in connection with the
                operation of the Facebook Fan Page and on the basis of the GDPR,
                in order to obtain anonymized statistical data about Our users.
              </p>
              <p>
                For this purpose, Facebook places a Cookie on the device of the
                user visiting Our Facebook Fan Page. Each Cookie contains a
                unique identifier code and remains active for a period of two
                years, except when it is deleted before the end of this period.
              </p>
              <p>
                Facebook receives, records and processes the information stored
                in the Cookie, especially when the user visits the Facebook
                services, services that are provided by other members of the
                Facebook Fan Page and services by other companies that use
                Facebook services.
              </p>
              <p>
                For more information on the privacy practices of Facebook,
                please visit Facebook Privacy Policy here:
                <a
                  href="https://www.facebook.com/full_data_use_policy"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://www.facebook.com/full_data_use_policy
                </a>
              </p>
              <h2>CCPA Privacy</h2>
              <p>
                This privacy notice section for California residents supplements
                the information contained in Our Privacy Policy and it applies
                solely to all visitors, users, and others who reside in the
                State of California.
              </p>
              <h3>Categories of Personal Information Collected</h3>
              <p>
                We collect information that identifies, relates to, describes,
                references, is capable of being associated with, or could
                reasonably be linked, directly or indirectly, with a particular
                Consumer or Device. The following is a list of categories of
                personal information which we may collect or may have been
                collected from California residents within the last twelve (12)
                months.
              </p>
              <p>
                Please note that the categories and examples provided in the
                list below are those defined in the CCPA. This does not mean
                that all examples of that category of personal information were
                in fact collected by Us, but reflects our good faith belief to
                the best of our knowledge that some of that information from the
                applicable category may be and may have been collected. For
                example, certain categories of personal information would only
                be collected if You provided such personal information directly
                to Us.
              </p>
              <ol>
                <li>
                  Category A: Identifiers.
                  <br />
                  Examples: A real name, alias, postal address, unique personal
                  identifier, online identifier, Internet Protocol address,
                  email address, account name, driver’s license number, passport
                  number, or other similar identifiers.
                  <br />
                  Collected: Yes.
                </li>
                <li>
                  Category B: Personal information categories listed in the
                  California Customer Records statute (Cal. Civ. Code §
                  1798.80(e)).
                  <br />
                  Examples: A name, signature, Social Security number, physical
                  characteristics or description, address, telephone number,
                  passport number, driver’s license or state identification card
                  number, insurance policy number, education, employment,
                  employment history, bank account number, credit card number,
                  debit card number, or any other financial information, medical
                  information, or health insurance information. Some personal
                  information included in this category may overlap with other
                  categories.
                  <br />
                  Collected: Yes.
                </li>
                <li>
                  Category C: Protected classification characteristics under
                  California or federal law.
                  <br />
                  Examples: Age (40 years or older), race, color, ancestry,
                  national origin, citizenship, religion or creed, marital
                  status, medical condition, physical or mental disability, sex
                  (including gender, gender identity, gender expression,
                  pregnancy or childbirth and related medical conditions),
                  sexual orientation, veteran or military status, genetic
                  information (including familial genetic information).
                  <br />
                  Collected: No.
                </li>
                <li>
                  Category D: Commercial information.
                  <br />
                  Examples: Records and history of products or services
                  purchased or considered.
                  <br />
                  Collected: Yes.
                </li>
                <li>
                  Category E: Biometric information.
                  <br />
                  Examples: Genetic, physiological, behavioral, and biological
                  characteristics, or activity patterns used to extract a
                  template or other identifier or identifying information, such
                  as, fingerprints, faceprints, and voiceprints, iris or retina
                  scans, keystroke, gait, or other physical patterns, and sleep,
                  health, or exercise data.
                  <br />
                  Collected: No.
                </li>
                <li>
                  Category F: Internet or other similar network activity.
                  <br />
                  Examples: Interaction with our Service or advertisement.
                  <br />
                  Collected: Yes.
                </li>
                <li>
                  Category G: Geolocation data.
                  <br />
                  Examples: Approximate physical location.
                  <br />
                  Collected: Yes.
                </li>
                <li>
                  Category H: Sensory data.
                  <br />
                  Examples: Audio, electronic, visual, thermal, olfactory, or
                  similar information.
                  <br />
                  Collected: No.
                </li>
                <li>
                  Category I: Professional or employment-related information.
                  <br />
                  Examples: Current or past job history or performance
                  evaluations.
                  <br />
                  Collected: No.
                </li>
                <li>
                  Category J: Non-public education information (per the Family
                  Educational Rights and Privacy Act (20 U.S.C. Section 1232g,
                  34 C.F.R. Part 99)).
                  <br />
                  Examples: Education records directly related to a student
                  maintained by an educational institution or party acting on
                  its behalf, such as grades, transcripts, class lists, student
                  schedules, student identification codes, student financial
                  information, or student disciplinary records.
                  <br />
                  Collected: No.
                </li>
                <li>
                  Category K: Inferences drawn from other personal information.
                  <br />
                  Examples: Profile reflecting a person’s preferences,
                  characteristics, psychological trends, predispositions,
                  behavior, attitudes, intelligence, abilities, and aptitudes.
                  <br />
                  Collected: No.
                </li>
              </ol>
              <p>Under CCPA, personal information does not include:</p>
              <ol>
                <li>Publicly available information from government records</li>
                <li>Deidentified or aggregated consumer information</li>
                <li>
                  Information excluded from the CCPA’s scope, such as:
                  <ol>
                    <li>
                      Health or medical information covered by the Health
                      Insurance Portability and Accountability Act of 1996
                      (HIPAA) and the California Confidentiality of Medical
                      Information Act (CMIA) or clinical trial data
                    </li>
                    <li>
                      Personal Information covered by certain sector-specific
                      privacy laws, including the Fair Credit Reporting Act
                      (FRCA), the Gramm-Leach-Bliley Act (GLBA) or California
                      Financial Information Privacy Act (FIPA), and the Driver’s
                      Privacy Protection Act of 1994
                    </li>
                  </ol>
                </li>
              </ol>
              <h3>Sources of Personal Information</h3>
              <p>
                We obtain the categories of personal information listed above
                from the following categories of sources:
              </p>
              <ol>
                <li>
                  Directly from You. For example, from the forms You complete on
                  our Service, preferences You express or provide through our
                  Service, or from Your purchases on our Service.
                </li>
                <li>
                  Indirectly from You. For example, from observing Your activity
                  on our Service.
                </li>
                <li>
                  Automatically from You. For example, through cookies We or our
                  Service Providers set on Your Device as You navigate through
                  our Service.
                </li>
                <li>
                  From Service Providers. For example, third-party vendors to
                  monitor and analyze the use of our Service, to provide
                  advertising on our Service, payment processing, or other
                  third-party vendors that We use to provide the Service to You.
                  It will only be captured after the users consent to it.
                </li>
              </ol>
              <h3>
                Use of Personal Information for Business Purposes or Commercial
                Purposes
              </h3>
              <p>
                We may use or disclose personal information We collect for
                “business purposes” or “commercial purposes” (as defined under
                the CCPA), which may include the following examples:
              </p>
              <ol>
                <li>
                  To operate our Service and provide You with our Service.
                </li>
                <li>
                  To provide You with support and to respond to Your inquiries,
                  including to investigate and address Your concerns and monitor
                  and improve our Service.
                </li>
                <li>
                  To fulfill or meet the reason You provided the information.
                  For example, if You share Your contact information to ask a
                  question about our Service, We will use that personal
                  information to respond to Your inquiry. If You provide Your
                  personal information to purchase a product or service, We will
                  use that information to process Your payment and facilitate
                  delivery.
                </li>
                <li>
                  To respond to law enforcement requests and as required by
                  applicable law, court order, or governmental regulations.
                </li>
                <li>
                  As described to You when collecting Your personal information
                  or as otherwise set forth in the CCPA.
                </li>
                <li>For internal administrative and auditing purposes.</li>
                <li>
                  To detect security incidents and protect against malicious,
                  deceptive, fraudulent or illegal activity, including, when
                  necessary, to prosecute those responsible for such activities.
                </li>
              </ol>
              <p>
                Please note that the examples provided above are illustrative
                and not intended to be exhaustive. For more details on how we
                use this information, please refer to the “Use of Your Personal
                Data” section.
              </p>
              <p>
                If We decide to collect additional categories of personal
                information or use the personal information We collected for
                materially different, unrelated, or incompatible purposes We
                will update this Privacy Policy.
              </p>
              <h3>
                Disclosure of Personal Information for Business Purposes or
                Commercial Purposes
              </h3>
              <p>
                We may use or disclose and may have used or disclosed in the
                last twelve (12) months the following categories of personal
                information for business or commercial purposes:
              </p>
              <ol>
                <li>Category A: Identifiers</li>
                <li>
                  Category B: Personal information categories listed in the
                  California Customer Records statute (Cal. Civ. Code §
                  1798.80(e))
                </li>
                <li>Category D: Commercial information</li>
                <li>Category F: Internet or other similar network activity</li>
                <li>Category G: Geolocation data</li>
              </ol>
              <p>
                Please note that the categories listed above are those defined
                in the CCPA. This does not mean that all examples of that
                category of personal information were in fact disclosed, but
                reflects our good faith belief to the best of our knowledge that
                some of that information from the applicable category may be and
                may have been disclosed.
              </p>
              <p>
                When We disclose personal information for a business purpose or
                a commercial purpose, We enter a contract that describes the
                purpose and requires the recipient to both keep that personal
                information confidential and not use it for any purpose except
                performing the contract.
              </p>
              <h3>Sale of Personal Information</h3>
              <p>
                As defined in the CCPA, “sell” and “sale” mean selling, renting,
                releasing, disclosing, disseminating, making available,
                transferring, or otherwise communicating orally, in writing, or
                by electronic or other means, a consumer’s personal information
                by the business to a third party for valuable consideration.
                This means that We may have received some kind of benefit in
                return for sharing personal information, but not necessarily a
                monetary benefit.
              </p>
              <p>
                Please note that the categories listed below are those defined
                in the CCPA. This does not mean that all examples of that
                category of personal information were in fact sold, but reflects
                our good faith belief to the best of our knowledge that some of
                that information from the applicable category may be and may
                have been shared for value in return.
              </p>
              <p>
                We may sell and may have sold in the last twelve (12) months the
                following categories of personal information:
              </p>
              <ol>
                <li>Category A: Identifiers</li>
                <li>
                  Category B: Personal information categories listed in the
                  California Customer Records statute (Cal. Civ. Code §
                  1798.80(e))
                </li>
                <li>Category D: Commercial information</li>
                <li>Category F: Internet or other similar network activity</li>
                <li>Category G: Geolocation data</li>
              </ol>
              <h3>Share of Personal Information</h3>
              <p>
                We may share Your personal information identified in the above
                categories with the following categories of third parties:
              </p>
              <ol>
                <li>Service Providers</li>
                <li>Payment processors</li>
                <li>Our affiliates</li>
                <li>Our business partners</li>
                <li>
                  Third party vendors to whom You or Your agents authorize Us to
                  disclose Your personal information in connection with products
                  or services We provide to You
                </li>
              </ol>
              <h3>YAASS POLICY TOWARDS AGE. </h3>
              <p>
                Even though we want as much people as possible to enjoy our
                platform, we require that you must be at least 18 years old to
                use our platform – sorry kids, YAASS is pretty cool, but you
                would have to comeback when your reach the age requirement.
              </p>
              <p>
                It is to our knowledge that YAASS does not collect any
                information or conduct marketing to children, minors, or any
                individual under the age of 18. If you are under 18 years of
                age, do not submit your information to us. When we become aware
                that a child, minor, or any individual under the age of 18 who
                has registered and provided us with their personal information,
                we will begin the process to void their registration and delete
                known Registration Information from YAASS. Deleted profiles who
                have violated our no children rules may be subject to retention
                of their email and IP address to eliminate the possibility of
                creating a new profile around our rules.
              </p>
              <h3>Your Rights under the CCPA</h3>
              <p>
                The CCPA provides California residents with specific rights
                regarding their personal information. If You are a resident of
                California, You have the following rights:
              </p>
              <ol>
                <li>
                  The right to notice. You have the right to be notified which
                  categories of Personal Data are being collected and the
                  purposes for which the Personal Data is being used.
                </li>
                <li>
                  The right to request. Under CCPA, You have the right to
                  request that We disclose information to You about Our
                  collection, use, sale, disclosure for business purposes and
                  share of personal information. Once We receive and confirm
                  Your request, We will disclose to You:
                  <ol>
                    <li>
                      The categories of personal information We collected about
                      You
                    </li>
                    <li>
                      The categories of sources for the personal information We
                      collected about You
                    </li>
                    <li>
                      Our business or commercial purpose for collecting or
                      selling that personal information
                    </li>
                    <li>
                      The categories of third parties with whom We share that
                      personal information
                    </li>
                    <li>
                      The specific pieces of personal information We collected
                      about You
                    </li>
                    <li>
                      If we sold Your personal information or disclosed Your
                      personal information for a business purpose, We will
                      disclose to You:
                      <ol>
                        <li>
                          The categories of personal information categories sold
                        </li>
                        <li>
                          The categories of personal information categories
                          disclosed
                        </li>
                      </ol>
                    </li>
                    <li>
                      The right to say no to the sale of Personal Data
                      (opt-out). You have the right to direct Us to not sell
                      Your personal information. To submit an opt-out request
                      please contact Us.
                    </li>
                    <li>
                      The right to delete Personal Data. You have the right to
                      request the deletion of Your Personal Data, subject to
                      certain exceptions. Once We receive and confirm Your
                      request, We will delete (and direct Our Service Providers
                      to delete) Your personal information from our records,
                      unless an exception applies. We may deny Your deletion
                      request if retaining the information is necessary for Us
                      or Our Service Providers to:
                      <ol>
                        <li>
                          Complete the transaction for which We collected the
                          personal information, provide a good or service that
                          You requested, take actions reasonably anticipated
                          within the context of our ongoing business
                          relationship with You, or otherwise perform our
                          contract with You.
                        </li>
                        <li>
                          Detect security incidents, protect against malicious,
                          deceptive, fraudulent, or illegal activity, or
                          prosecute those responsible for such activities.
                        </li>
                        <li>
                          Debug products to identify and repair errors that
                          impair existing intended functionality.
                        </li>
                        <li>
                          Exercise free speech, ensure the right of another
                          consumer to exercise their free speech rights, or
                          exercise another right provided for by law.
                        </li>
                        <li>
                          Comply with the California Electronic Communications
                          Privacy Act (Cal. Penal Code § 1546 et. seq.).
                        </li>
                        <li>
                          Engage in public or peer-reviewed scientific,
                          historical, or statistical research in the public
                          interest that adheres to all other applicable ethics
                          and privacy laws, when the information’s deletion may
                          likely render impossible or seriously impair the
                          research’s achievement, if You previously provided
                          informed consent.
                        </li>
                        <li>
                          Enable solely internal uses that are reasonably
                          aligned with consumer expectations based on Your
                          relationship with Us.
                        </li>
                        <li>Comply with a legal obligation.</li>
                        <li>
                          Make other internal and lawful uses of that
                          information that are compatible with the context in
                          which You provided it.
                        </li>
                      </ol>
                    </li>
                    <li>
                      The right not to be discriminated against. You have the
                      right not to be discriminated against for exercising any
                      of Your consumer’s rights, including by:
                      <ol>
                        <li>Denying goods or services to You</li>
                        <li>
                          Charging different prices or rates for goods or
                          services, including the use of discounts or other
                          benefits or imposing penalties
                        </li>
                        <li>
                          Providing a different level or quality of goods or
                          services to You
                        </li>
                        <li>
                          Suggesting that You will receive a different price or
                          rate for goods or services or a different level or
                          quality of goods or services
                        </li>
                      </ol>
                    </li>
                  </ol>
                </li>
              </ol>
              <h4>Geolocation Information</h4>
              <ol>
                <li>
                  If you turn this feature on while using your mobile device, we
                  will collect information relating to WiFi access points and
                  other location information including your longitude and
                  latitude and your mobile device’s geolocation coordinates to
                  offer you certain features. This data will allow us to
                  identify your physical location that we may use to personalize
                  the App to make it easier for you to interact with other
                  Users. This will be performed by enabling information to be
                  shared and displayed with other members by choosing to view
                  “nearby” posts.
                </li>
                <li>
                  If location services are enabled and you want to turn them off
                </li>
              </ol>
              <h2>4. WHAT OTHERS MAY SEE ABOUT YOU.</h2>
              <p>
                Our YAASS users are amazing. We also want users to share how
                amazing they are with the world. So, we have built-in certain
                features to allow this. We designed our App to make it easier
                for users connect and interact with other users. When
                registering or logging in to the App using other social media
                accounts, you are giving us authorization to access certain
                social media account’s information. This includes information
                that you make available through the social media account, your
                friends list, current location, and friends you have in common
                with YAASS users. You also understand that your YAASS profile
                and other information you make available through the app,
                including information provided directly or indirectly through
                those social media accounts (photos, name, age, location, common
                friends, other profile information), may be viewed and shared by
                YAASS users with individuals who may not be users on YAASS or
                through third party applications in such YAASS users’
                discretion. As an example, a user may share your picture to a
                social media acquaintance to recommend you as a match for them,
                regardless of them being an App user. You might share
                information in relation to pictures and messages you upload or
                send and any metadata within those messages, along with comment
                and information you provide in connection with your pictures. As
                a YAASS user, assume anything that you submit or post on the app
                may be publicly viewable and accessible by both Users and
                non-User of the app. As information will eventually be made
                public, we want YAASS users to be careful of posting
                information. When logging in or accessing YAAS through another
                social media account’s profile, keep in mind to log off from the
                same social media accounts later when you leave a device
                unattended to prevent accessibility to your account for other
                users.
              </p>
              <h3>Exercising Your CCPA Data Protection Rights</h3>
              <p>
                In order to exercise any of Your rights under the CCPA, and if
                You are a California resident, You can contact Us:
              </p>
              <ol>
                <li>By email: info@yaass.com</li>
              </ol>
              <p>
                Only You, or a person registered with the California Secretary
                of State that You authorize to act on Your behalf, may make a
                verifiable request related to Your personal information.
              </p>
              <p>Your request to Us must:</p>
              <ol>
                <li>
                  Provide sufficient information that allows Us to reasonably
                  verify You are the person about whom We collected personal
                  information or an authorized representative
                </li>
                <li>
                  Describe Your request with sufficient detail that allows Us to
                  properly understand, evaluate, and respond to it
                </li>
              </ol>
              <p>
                We cannot respond to Your request or provide You with the
                required information if We cannot:
              </p>
              <ol>
                <li>Verify Your identity or authority to make the request</li>
                <li>
                  And confirm that the personal information relates to You
                </li>
              </ol>
              <p>
                We will disclose and deliver the required information free of
                charge within 45 days of receiving Your verifiable request. The
                time period to provide the required information may be extended
                once by an additional 45 days when reasonable necessary and with
                prior notice.
              </p>
              <p>
                Any disclosures We provide will only cover the 12-month period
                preceding the verifiable request’s receipt.
              </p>
              <p>
                For data portability requests, We will select a format to
                provide Your personal information that is readily useable and
                should allow You to transmit the information from one entity to
                another entity without hindrance.
              </p>
              <h3>Do Not Sell My Personal Information</h3>
              <p>
                You have the right to opt-out of the sale of Your personal
                information. Once We receive and confirm a verifiable consumer
                request from You, we will stop selling Your personal
                information. To exercise Your right to opt-out, please contact
                Us.
              </p>
              <p>
                The Service Providers we partner with (for example, our
                analytics or advertising partners) may use technology on the
                Service that sells personal information as defined by the CCPA
                law. If you wish to opt out of the use of Your personal
                information for interest-based advertising purposes and these
                potential sales as defined under CCPA law, you may do so by
                following the instructions below.
              </p>
              <p>
                Please note that any opt out is specific to the browser You use.
                You may need to opt out on every browser that You use.
              </p>
              <h4>Website</h4>
              <p>
                You can opt out of receiving ads that are personalized as served
                by our Service Providers by following our instructions presented
                on the Service:
              </p>
              <ol>
                <li>
                  The NAI’s opt-out platform:
                  <a
                    href="http://www.networkadvertising.org/choices/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    http://www.networkadvertising.org/choices/
                  </a>
                </li>
                <li>
                  The EDAA’s opt-out platform
                  <a
                    href="http://www.youronlinechoices.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    http://www.youronlinechoices.com/
                  </a>
                </li>
                <li>
                  The DAA’s opt-out platform:
                  <a
                    href="http://optout.aboutads.info/?c=2&amp;lang=EN"
                    target="_blank"
                    rel="noreferrer"
                  >
                    http://optout.aboutads.info/?c=2&amp;lang=EN
                  </a>
                </li>
              </ol>
              <p>
                The opt out will place a cookie on Your computer that is unique
                to the browser You use to opt out. If you change browsers or
                delete the cookies saved by your browser, You will need to opt
                out again.
              </p>
              <h4>Mobile Devices</h4>
              <p>
                Your mobile device may give You the ability to opt out of the
                use of information about the apps You use in order to serve You
                ads that are targeted to Your interests:
              </p>
              <ol>
                <li>
                  “Opt out of Interest-Based Ads” or “Opt out of Ads
                  Personalization” on Android devices
                </li>
                <li>“Limit Ad Tracking” on iOS devices</li>
              </ol>
              <p>
                You can also stop the collection of location information from
                Your mobile device by changing the preferences on Your mobile
                device.
              </p>
              <h2>
                “Do Not Track” Policy as Required by California Online Privacy
                Protection Act (CalOPPA)
              </h2>
              <p>Our Service does not respond to Do Not Track signals.</p>
              <p>
                However, some third party websites do keep track of Your
                browsing activities. If You are visiting such websites, You can
                set Your preferences in Your web browser to inform websites that
                You do not want to be tracked. You can enable or disable DNT by
                visiting the preferences or settings page of Your web browser.
              </p>
              <h2>
                Your California Privacy Rights (California’s Shine the Light
                law)
              </h2>
              <p>
                Under California Civil Code Section 1798 (California’s Shine the
                Light law), California residents with an established business
                relationship with us can request information once a year about
                sharing their Personal Data with third parties for the third
                parties’ direct marketing purposes.
              </p>
              <p>
                If you’d like to request more information under the California
                Shine the Light law, and if You are a California resident, You
                can contact Us using the contact information provided below.
              </p>
              <h2>
                California Privacy Rights for Minor Users (California Business
                and Professions Code Section 22581)
              </h2>
              <p>
                California Business and Professions Code section 22581 allow
                California residents under the age of 18 who are registered
                users of online sites, services or applications to request and
                obtain removal of content or information they have publicly
                posted.
              </p>
              <p>
                To request removal of such data, and if You are a California
                resident, You can contact Us using the contact information
                provided below, and include the email address associated with
                Your account.
              </p>
              <p>
                Be aware that Your request does not guarantee complete or
                comprehensive removal of content or information posted online
                and that the law may not permit or require removal in certain
                circumstances.
              </p>
              <h2>Links to Other Websites</h2>
              <p>
                Our Service may contain links to other websites that are not
                operated by Us. If You click on a third party link, You will be
                directed to that third party’s site. We strongly advise You to
                review the Privacy Policy of every site You visit.
              </p>
              <p>
                We have no control over and assume no responsibility for the
                content, privacy policies or practices of any third party sites
                or services.
              </p>
              <h2>Changes to this Privacy Policy</h2>
              <p>
                We may update Our Privacy Policy from time to time. We will
                notify You of any changes by posting the new Privacy Policy on
                this page.
              </p>
              <p>
                We will let You know via email and/or a prominent notice on Our
                Service, prior to the change becoming effective and update the
                “Last updated” date at the top of this Privacy Policy.
              </p>
              <p>
                You are advised to review this Privacy Policy periodically for
                any changes. Changes to this Privacy Policy are effective when
                they are posted on this page.
              </p>
              <h2>Contact Us</h2>
              <p>
                If you have any questions about this Privacy Policy, You can
                contact us:
              </p>
              <p>
                <span>By email:</span> admin@yaass.com
              </p>
            </div>
            <div><br/></div>
            <div>
              <h1><strong>Privacy Policy for India:</strong></h1>
              <p>Last updated: 20 June 2023</p>
              <p>This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You.</p>
              <p>We use Your Personal data to provide and improve the Service. By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy.</p>
              <h2><strong>Interpretation and Definitions</strong></h2>
              <h3><strong>Interpretation</strong></h3>
              <p>The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</p>
              <h3><strong>Definitions</strong></h3>
              <p>For the purposes of this Privacy Policy:</p>
              <ul>
                <li><strong>Account</strong> means a unique account created for You to access our Service or parts of our Service.</li>
                <li><strong>Company</strong> (referred to as either 'the Company', 'We', 'Us' or 'Our' in this Agreement) refers to YAASS LLC, 55 E. Monroe Street, Suite 3800.</li>
                <li><strong>Cookies</strong> are small files that are placed on Your computer, mobile device or any other device by a website, containing the details of Your browsing history on that website among its many uses.</li>
                <li><strong>Country</strong> refers to: Illinois, United States</li>
                <li><strong>Device</strong> means any device that can access the Service such as a computer, a cellphone or a digital tablet.</li>
                <li><strong>Personal Data</strong> is any information that relates to an identified or identifiable individual.</li>
                <li><strong>Service</strong> refers to the Website.</li>
                <li><strong>Service Provider</strong> means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used.</li>
                <li><strong>Third-party Social Media Service</strong> refers to any website or any social network website through which a User can log in or create an account to use the Service.</li>
                <li><strong>Usage Data</strong> refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).</li>
                <li><strong>Website</strong> refers to YAASS LLC, accessible from <a href="https://www.yaass.com/">yaass.com</a></li>
                <li><strong>You</strong> means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</li>
              </ul>
              <h2><strong>Collecting and Using Your Personal Data</strong></h2>
              <h3><strong>Types of Data Collected</strong></h3>
              <h3><strong>Personal Data</strong></h3>
              <p>While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You. Personally identifiable information may include, but is not limited to:</p>
              <ul>
                <li>Email address</li>
                <li>First name and last name</li>
                <li>Phone number</li>
                <li>Address, State, Province, ZIP/Postal code, City</li>
                <li>Usage Data</li>
              </ul>
              <h3><strong>Usage Data</strong></h3>
              <p>Usage Data is collected automatically when using the Service.</p>
              <p>Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that You visit, the time and date of Your visit, the time spent on those pages, unique device identifiers and other diagnostic data.</p>
              <p>When You access the Service by or through a mobile device, We may collect certain information automatically, including, but not limited to, the type of mobile device You use, Your mobile device unique ID, the IP address of Your mobile device, Your mobile operating system, the type of mobile Internet browser You use, unique device identifiers and other diagnostic data.</p>
              <p>We may also collect information that Your browser sends whenever You visit our Service or when You access the Service by or through a mobile device.</p>
              <h3><strong>Tracking Technologies and Cookies</strong></h3>
              <p>We use Cookies and similar tracking technologies to track the activity on Our Service and store certain information. Tracking technologies used are beacons, tags, and scripts to collect and track information and to improve and analyze Our Service. The technologies We use may include:</p>
              <ul>
                <li><strong>Cookies or Browser Cookies.</strong> A cookie is a small file placed on Your Device. You can instruct Your browser to refuse all Cookies or to indicate when a Cookie is being sent. However, if You do not accept Cookies, You may not be able to use some parts of our Service. Unless you have adjusted Your browser setting so that it will refuse Cookies, our Service may use Cookies.</li>
                <li><strong>Flash Cookies.</strong> Certain features of our Service may use local stored objects (or Flash Cookies) to collect and store information about Your preferences or Your activity on our Service. Flash Cookies are not managed by the same browser settings as those used for Browser Cookies. For more information on how You can delete Flash Cookies, please read 'Where can I change the settings for disabling, or deleting local shared objects?' available at <a href="https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html#main_Where_can_I_change_the_settings_for_disabling__or_deleting_local_shared_objects_">https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html#main_Where_can_I_change_the_settings_for_disabling__or_deleting_local_shared_objects_</a></li>
                <li><strong>Web Beacons.</strong> Certain sections of our Service and our emails may contain small electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit the Company, for example, to count users who have visited those pages or opened an email and for other related website statistics (for example, recording the popularity of a certain section and verifying system and server integrity).</li>
              </ul>
              <p>Cookies can be 'Persistent' or 'Session' Cookies. Persistent Cookies remain on Your personal computer or mobile device when You go offline, while Session Cookies are deleted as soon as You close Your web browser.</p>
              <p>We use both Session and Persistent Cookies for the purposes set out below:</p>
              <ul>
                <li><strong>Necessary / Essential Cookies<br /> </strong>Type: Session Cookies<br /> Administered by: Us<br /> Purpose: These Cookies are essential to provide You with services available through the Website and to enable You to use some of its features. They help to authenticate users and prevent fraudulent use of user accounts. Without these Cookies, the services that You have asked for cannot be provided, and We only use these Cookies to provide You with those services.</li>
                <li><strong>Cookies Policy / Notice Acceptance Cookies<br /> </strong>Type: Persistent Cookies<br /> Administered by: Us<br /> Purpose: These Cookies identify if users have accepted the use of cookies on the Website.</li>
                <li><strong>Functionality Cookies<br /> </strong>Type: Persistent Cookies<br /> Administered by: Us<br /> Purpose: These Cookies allow us to remember choices You make when You use the Website, such as remembering your login details or language preference. The purpose of these Cookies is to provide You with a more personal experience and to avoid You having to re-enter your preferences every time You use the Website.</li>
              </ul>
              <p>For more information about the cookies we use and your choices regarding cookies, please visit our Cookies Policy or the Cookies section of our Privacy Policy.</p>
              <h3><strong>Use of Your Personal Data</strong></h3>
              <p>The Company may use Personal Data for the following purposes:</p>
              <ul>
                <li><strong>To provide and maintain our Service</strong>, including to monitor the usage of our Service.</li>
                <li><strong>To manage Your Account:</strong> to manage Your registration as a user of the Service. The Personal Data You provide can give You access to different functionalities of the Service that are available to You as a registered user.</li>
                <li><strong>For the performance of a contract:</strong> the development, compliance and undertaking of the purchase contract for the products, items or services You have purchased or of any other contract with Us through the Service.</li>
                <li><strong>To contact You:</strong> To contact You by email, telephone calls, SMS, or other equivalent forms of electronic communication, such as a mobile application's push notifications regarding updates or informative communications related to the functionalities, products or contracted services, including the security updates, when necessary or reasonable for their implementation.</li>
                <li><strong>To provide You</strong> with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless You have opted not to receive such information.</li>
                <li><strong>To manage Your requests:</strong> To attend and manage Your requests to Us.</li>
                <li><strong>For business transfers:</strong> We may use Your information to evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which Personal Data held by Us about our Service users is among the assets transferred.</li>
                <li><strong>For other purposes</strong>: We may use Your information for other purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns and to evaluate and improve our Service, products, services, marketing and your experience.</li>
              </ul>
              <p>We may share Your personal information in the following situations:</p>
              <ul>
                <li><strong>With Service Providers:</strong> We may share Your personal information with Service Providers to monitor and analyze the use of our Service, to contact You.</li>
                <li><strong>For business transfers:</strong> We may share or transfer Your personal information in connection with, or during negotiations of, any merger, sale of Company assets, financing, or acquisition of all or a portion of Our business to another company.</li>
                <li><strong>With Affiliates:</strong> We may share Your information with Our affiliates, in which case we will require those affiliates to honor this Privacy Policy. Affiliates include Our parent company and any other subsidiaries, joint venture partners or other companies that We control or that are under common control with Us.</li>
                <li><strong>With business partners:</strong> We may share Your information with Our business partners to offer You certain products, services or promotions.</li>
                <li><strong>With other users:</strong> when You share personal information or otherwise interact in the public areas with other users, such information may be viewed by all users and may be publicly distributed outside. If You interact with other users or register through a Third-Party Social Media Service, Your contacts on the Third-Party Social Media Service may see Your name, profile, pictures and description of Your activity. Similarly, other users will be able to view descriptions of Your activity, communicate with You and view Your profile.</li>
                <li><strong>With Your consent</strong>: We may disclose Your personal information for any other purpose with Your consent.</li>
              </ul>
              <h3><strong>Retention of Your Personal Data</strong></h3>
              <p>The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.</p>
              <p>The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of Our Service, or We are legally obligated to retain this data for longer time periods.</p>
              <h3><strong>Transfer of Your Personal Data</strong></h3>
              <p>Your information, including Personal Data, is processed at the Company's operating offices and in any other places where the parties involved in the processing are located. It means that this information may be transferred to &mdash; and maintained on &mdash; computers located outside of Your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from Your jurisdiction.</p>
              <p>Your consent to this Privacy Policy followed by Your submission of such information represents Your agreement to that transfer.</p>
              <p>The Company will take all steps reasonably necessary to ensure that Your data is treated securely and in accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of Your data and other personal information.</p>
              <h2><strong>Disclosure of Your Personal Data</strong></h2>
              <h3><strong>Business Transactions</strong></h3>
              <p>If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be transferred. We will provide notice before Your Personal Data is transferred and becomes subject to a different Privacy Policy.</p>
              <h3><strong>Law enforcement</strong></h3>
              <p>Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).</p>
              <h3><strong>Other legal requirements</strong></h3>
              <p>The Company may disclose Your Personal Data in the good faith belief that such action is necessary to:</p>
              <ul>
                <li>Comply with a legal obligation</li>
                <li>Protect and defend the rights or property of the Company</li>
                <li>Prevent or investigate possible wrongdoing in connection with the Service</li>
                <li>Protect the personal safety of Users of the Service or the public</li>
                <li>Protect against legal liability</li>
              </ul>
              <h3><strong>Security of Your Personal Data</strong></h3>
              <p>The security of Your Personal Data is important to Us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable means to protect Your Personal Data, We cannot guarantee its absolute security.</p>
              <h3><strong>Children's Privacy</strong></h3>
              <p>Our Service does not address anyone under the age of 13. We do not knowingly collect personally identifiable information from anyone under the age of 13. If You are a parent or guardian and You are aware that Your child has provided Us with Personal Data, please contact Us. If We become aware that We have collected Personal Data from anyone under the age of 13 without verification of parental consent, We take steps to remove that information from Our servers.</p>
              <p>If We need to rely on consent as a legal basis for processing Your information and Your country requires consent from a parent, We may require Your parent's consent before We collect and use that information.</p>
              <h3><strong>Links to Other Websites</strong></h3>
              <p>Our Service may contain links to other websites that are not operated by Us. If You click on a third party link, You will be directed to that third party's site. We strongly advise You to review the Privacy Policy of every site You visit.</p>
              <p>We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.</p>
              <h3><strong>Changes to this Privacy Policy</strong></h3>
              <p>We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page.</p>
              <p>We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming effective and update the 'Last updated' date at the top of this Privacy Policy.</p>
              <p>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>
              <h3><strong>Contact Us</strong></h3>
              <p>If you have any questions about this Privacy Policy, You can contact us:</p>
              <ul>
                <li>By email: admin@yaass.com</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default PrivacyPolicy;

import { Container, Typography } from "@mui/material";
import logo from "../images/logo.png";

const PageNotFound = () => {
  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <img
        src={logo}
        alt="white-logo"
        style={{
          width: 200,
          height: 200,
          margin: "auto",
          padding: "0",
        }}
      />
      <Typography variant="h1">404</Typography>
      <Typography variant="h2">OOPS! Page not found</Typography>
      <Typography variant="h3">
        The page you are looking for is missing or does not exist
      </Typography>
      <a href="/">
        <Typography
          sx={{
            m: 2,
          }}
        >
          Click here to go back to the home page
        </Typography>
      </a>
    </Container>
  );
};

export default PageNotFound;

import { createTheme, responsiveFontSizes, ThemeOptions } from "@mui/material";

const baseThemeOptions : ThemeOptions = {
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1920,
      },
    },
  
    palette: {
      mode: "light",
    },
    typography: {
      h1: {
        fontSize: 32,
        fontWeight: 800,
        fontFamily: 'Open Sans, sans-serif',
        color: "#826ee4",
      },
      h2: {
        fontSize: 24,
        fontWeight: "bold",
        fontFamily: 'Open Sans, sans-serif',
        color: "#826ee4",
      },
      h3: {
        fontSize: 13,
        fontWeight: 800,
        fontFamily: 'Open Sans, sans-serif',
        color: "#826ee4",
      },
      body1: {
        fontSize: 12,
        fontWeight: "bold",
        fontFamily: 'Open Sans, sans-serif',
        color: "#826ee4",
      },
      body2: {
        fontSize: 11.5,
        fontWeight: "bold",
        fontFamily: 'Open Sans, sans-serif',
        color: "black",
      }
    },
  };
  
  const theme = responsiveFontSizes(createTheme(baseThemeOptions));


export default theme
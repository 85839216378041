import { Box, Button, Link, Typography } from "@mui/material";
import { FunctionComponent } from "react";

const Landing: FunctionComponent = () => {
  const isMobile = window.innerWidth <= 800;

  return (
    <>
      <Box
        sx={{
          zIndex: -1,
          width: "100%",
          display: "flex",
        }}
      >
        <video
          muted
          autoPlay
          preload="auto"
          playsInline
          loop
          style={{
            zIndex: -1,
            width: "100vw",
            height: "100vh",
            objectFit: "cover",
          }}
        >
          <source
            src={
              !isMobile
                ? "https://yaass-website.s3.us-east-2.amazonaws.com/videos/YAASS-LaunchCampaign.mp4"
                : "https://yaass-website.s3.us-east-2.amazonaws.com/videos/YAASS-LaunchCampaign-Vertical-FINAL.mp4"
            }
            type="video/mp4"
          />
          
          Your browser does not support the video tag.
        </video>
        <Box
          sx={{
            position: "absolute",
            width: "100%",
            right: 0,
            p: 2,

            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              width: isMobile ? 0 : "10%",
            }}
          />
          <img
            src="https://yaass-website.s3.us-east-2.amazonaws.com/images/logo-white.png"
            alt="white-logo"
            style={{
              width: isMobile ? 150 : 200,
              height: isMobile ? 120 : 150,
            }}
          />
          <Button
            variant={"contained"}
            sx={{
              backgroundColor: "white",
              color: "purple",
              width: isMobile ? 160 : 180,
              height: isMobile ? 40 : 45,
              borderRadius: 3,
              textTransform: "none",
              float: "right",
              my: 5,
              "&:hover": {
                backgroundColor: "white",
              },
            }}
            disableElevation
            disableRipple
            onClick={() =>
              window.scrollBy({
                top: window.innerHeight + 75,
                behavior: "smooth",
              })
            }
          >
            <Typography
              sx={{
                fontFamily: "Nunito, sans-serif",
                color: "#8a4af3",
                fontSize: isMobile ? 24 : 28,
              }}
            >
              Download
            </Typography>
          </Button>
        </Box>
      </Box>
      <Box
        sx={{
          mt: 10,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "50%",
          }}
        >
          <Typography
            
            sx={{
              textAlign: "center",
              fontSize: isMobile ? 15 : 25,
              fontWeight: 'bold'
            }}
          >
            Privacy. Networking. Connection.
          </Typography>
          <img
            src="https://yaass-website.s3.us-east-2.amazonaws.com/images/phone-image.png"
            alt="phone"
            width={isMobile ? "100%" : "50%"}
            style={{
              objectFit: "cover",
              margin: 0,
              padding: 0,
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "50%",
          }}
        >
          <img
            src="https://yaass-website.s3.us-east-2.amazonaws.com/images/peopls.png"
            alt="people"
            width={isMobile ? "100%" : "60%"}
            style={{
              objectFit: "cover",
            }}
          />

          <Typography
            
            sx={{
              textAlign: "center",
              m: 1,
              fontSize: isMobile ? 15 : 25,
              fontWeight: 'bold'
            }}
          >
            Start connecting now!
          </Typography>
          <Link href="https://play.google.com/store/apps/details?id=com.yaass.app&hl=en_US&gl=US">
            <img
              src="https://yaass-website.s3.us-east-2.amazonaws.com/images/Google.png"
              alt="google"
              width={isMobile ? "100%" : "240px"}
              style={{
                objectFit: "cover",
              }}
            />
          </Link>
          <Link href="https://apps.apple.com/us/app/yaass/id1562493671">
            <img
              src="https://yaass-website.s3.us-east-2.amazonaws.com/images/Apple.png"
              alt="apple"
              width={isMobile ? "100%" : "240px"}
              style={{
                objectFit: "cover",
              }}
            />
          </Link>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: 250,
          mx: "auto",
          my: 0,
        }}
      >
        <Link href="https://www.facebook.com/YAASSapp/">
          <img
            src="https://yaass-website.s3.us-east-2.amazonaws.com/images/facebook.png"
            alt="facebook"
            width={"48px"}
          />
        </Link>
        <Link href="https://instagram.com/yaass_app">
          <img
            src="https://yaass-website.s3.us-east-2.amazonaws.com/images/insta.png"
            alt="insta"
            width={"48px"}
          />
        </Link>
        <Link href="https://www.linkedin.com/company/yaass">
          <img
            src="https://yaass-website.s3.us-east-2.amazonaws.com/images/link.png"
            alt="linkedin"
            width={"48px"}
          />
        </Link>
        <Link href="https://twitter.com/YAASS_app?s=09">
          <img
            src="https://yaass-website.s3.us-east-2.amazonaws.com/images/twitter.png"
            alt="google"
            width={"48px"}
          />
        </Link>
      </Box>
    </>
  );
};

export default Landing;
